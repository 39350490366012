import { Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ShareDialog from 'src/components/ShareModal/ShareDialog';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useUpdateContentAccessRights from 'src/hooks/useUpdateAccessRights';
import { updateAssistantContext } from 'src/redux/slices/assistant';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  useLazyGetAssistantContentAccessRightsQuery,
  useLazyGetContentAccessRightsQuery,
  useLazyGetRolesQuery
} from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { StringKeys } from 'src/types/base';
import { DEFAULT_CONTENT_ROLES, RESOURCE_TYPE } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { sharingRoleDescriptions } from '../utils/utils';
import InviteUsersForm from './InviteUsersForm';
import SharedWith from './SharedWith';
import { VerticalContainer } from './styles';

type IProps = {
  isOpen: boolean;
  onClose: () => void;
  resource?: any;
  isExternalLinkedResource?: boolean;
  resourceType?: string;
};

type FormValues = {
  users?: any[];
  role: any;
  // message?: string;
  // sendEmail: boolean;
  advanced: string;
};

const ContentSharing: React.FC<IProps> = ({
  isOpen,
  onClose,
  resource,
  isExternalLinkedResource = false,
  resourceType = RESOURCE_TYPE.FILE
}: IProps) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { applicationResources } = useSelector((state) => state.data);
  const { user } = useSelector((state) => state.auth);
  const { assistantContext } = useSelector((state) => state.assistant);
  const [
    fetchResourceRoles,
    { data: contentRoles, isLoading: isRolesLoading }
  ] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetRolesQuery,
    errorContext: ErrorContext.ROLES
  });
  const [
    fetchContentAccessDetail,
    { data: contentAccessRights, isLoading: isLoadingPermissions, isFetching }
  ] = useLazyQuery<DataApiInputParams, any[]>({
    api: useLazyGetContentAccessRightsQuery
  });
  const [
    fetchAssistantContentAccessDetail,
    {
      data: assistantContentAccessRights,
      isLoading: isLoadingAssistantPermissions,
      isFetching: isAssistantPermissionFetching
    }
  ] = useLazyQuery<DataApiInputParams, any[]>({
    api: useLazyGetAssistantContentAccessRightsQuery
  });
  const { handleUpdateAccessRights, isUpdatingAccess } =
    useUpdateContentAccessRights();

  const formMethods = useForm<FormValues>();
  const { handleSubmit, watch } = formMethods;
  const [removedAccessRights, setRemovedAccessRight] = useState<any[]>([]);
  const watchUsers = watch('users', []);
  const { id: resourceId, name: resourceName } = resource || {
    id: null,
    name: null
  };

  const contentRolesWithSecondaryText = useMemo(() => {
    if (contentRoles) {
      return contentRoles.map((item) => ({
        ...item,
        secondaryText: t(sharingRoleDescriptions[item.name] || item.description)
      }));
    }
    return [];
  }, [contentRoles, i18n.language]);

  const isAssistant: boolean = useMemo(
    () => resourceType === RESOURCE_TYPE.ASSISTANT,
    [resourceType]
  );

  useEffect(() => {
    if (isAssistant) {
      fetchResourceRoles({
        params: {
          params: {
            sorting: true,
            show_details: true,
            requested_roles: `${DEFAULT_CONTENT_ROLES.VIEWER},${DEFAULT_CONTENT_ROLES.CONTROLLER}`
          }
        }
      });
      fetchAssistantContentAccessDetail({
        params: { params: { id: resourceId } }
      });
    } else {
      fetchResourceRoles({
        params: { params: { sorting: true, show_details: true } }
      });
      fetchContentAccessDetail({ params: { params: { id: resourceId } } });
    }
  }, []);

  const handleSearch = useCallback(
    debounce((s: string) => {
      if (isAssistant) {
        fetchAssistantContentAccessDetail({
          params: { params: { s, id: resourceId } }
        });
      } else {
        fetchContentAccessDetail({ params: { params: { s, id: resourceId } } });
      }
    }, 250),
    [isAssistant]
  );

  const onSubmit = handleSubmit((data: any) => {
    let removedUsers = [];
    if (isAssistant) {
      removedUsers = assistantContentAccessRights?.filter((accessRight) => {
        if (removedAccessRights.includes(accessRight.p_id)) {
          return accessRight;
        }
      });
      if (assistantContext?.isCreatingAssistant) {
        dispatch(
          updateAssistantContext({
            ...assistantContext,
            isCreatingAssistant: false
          })
        );
      }
    } else {
      removedUsers = contentAccessRights?.filter((accessRight) =>
        removedAccessRights.includes(accessRight.p_id)
      );
    }
    handleUpdateAccessRights({
      id: resourceId,
      role: data.role,
      addedUsers: data.users,
      removedUsers: removedUsers,
      assignmentType: data.advanced,
      type_id: applicationResources.User,
      isAssistant: isAssistant
    });

    onClose();
  });

  const handleKeepPrivate = () => {
    if (assistantContentAccessRights) {
      // this is the logic to remove the existing shared with users/groups

      // handleUpdateAccessRights({
      //   id: resourceId,
      //   role: formMethods.getValues('role'),
      //   addedUsers: formMethods.getValues('users'),
      //   removedUsers: assistantContentAccessRights.filter(
      //     (usr) => usr.value !== user?.username
      //   ),
      //   assignmentType: formMethods.getValues('advanced'),
      //   type_id: applicationResources.User
      // });

      dispatch(
        updateAssistantContext({
          ...assistantContext,
          isCreatingAssistant: false
        })
      );
      onClose();
    }
  };

  const dialogTitle = `${t(T.share)} ${
    resourceName ? ` - ${resourceName}` : ''
  }`;

  const isActionButtonDisabled =
    (watchUsers?.length === 0 && removedAccessRights.length === 0) ||
    isUpdatingAccess;

  return (
    <ShareDialog
      isOpen={isOpen}
      onClose={onClose}
      isActionButtonVisible={!isExternalLinkedResource}
      isActionButtonDisabled={isActionButtonDisabled}
      actionButtonText={T.confirm}
      handleActionButton={onSubmit}
      title={dialogTitle}
      sendTabLabel={T.invite}
      sentToTabLabel={T.sharedWith}
      isActionInProgress={isUpdatingAccess}
      isDataLoading={
        isRolesLoading || isLoadingPermissions || isLoadingAssistantPermissions
      }
      handleSecondaryButton={
        isAssistant && assistantContext?.isCreatingAssistant
          ? handleKeepPrivate
          : onClose
      }
      secondaryButtonText={
        isAssistant && assistantContext?.isCreatingAssistant
          ? T.keepItPrivate
          : T.cancel
      }
    >
      {isExternalLinkedResource ? (
        <VerticalContainer>
          <Typography>{t(T.externalResourceDisclaimer)}</Typography>
        </VerticalContainer>
      ) : (
        <InviteUsersForm
          contentRoles={contentRolesWithSecondaryText}
          formMethods={formMethods}
          isAssistant={isAssistant}
        />
      )}

      <SharedWith
        isLoading={isFetching || isAssistantPermissionFetching}
        handleSearch={handleSearch}
        contentAccessRights={
          isAssistant ? assistantContentAccessRights : contentAccessRights
        }
        removedAccessRights={removedAccessRights}
        setRemovedAccessRight={setRemovedAccessRight}
        isExternalLinkedResource={isExternalLinkedResource}
      />
    </ShareDialog>
  );
};

export default ContentSharing;
