import { TableEditColumn } from '@devexpress/dx-react-grid-material-ui';
import { TabPanel } from '@mui/lab';
import {
  Box,
  ButtonBase,
  Chip,
  Tab,
  Tabs,
  ToggleButton,
  Typography,
  alpha,
  styled
} from '@mui/material';
import { EditActionCell, TableCellHeader } from 'src/components/Grid/styles';

type StyledTabProps = {
  bordered?: boolean;
};

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'bordered'
})(({ bordered = false }: StyledTabProps) => ({
  fontWeight: '400 !important',
  fontSize: '16px',
  margin: '0 2px',
  borderRadius: 0, // Disable borderRadius here,
  ['&.Mui-selected']: {
    borderBottom: '3.5px solid #7BF3B9',
    borderRadius: '0px!important',
    '&:hover': {
      borderRadius: 0
    },
    ['& .MuiTab-iconWrapper']: {
      borderRadius: '12px',
      backgroundColor: '#7BF3B9'
    }
  },
  ['&.MuiTab-root']: {
    height: '44px'
  }
}));
export const StyledTabPanel = styled(TabPanel)(() => ({
  display: 'flex',
  height: 'calc(100% - 60px)',
  padding: 0
}));

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  minHeight: '45px',
  maxWidth: 'max-content',
  '&.MuiTabs-root': { backgroundColor: '#fff' },
  '& .MuiTabs-scroller': {
    height: '44px',
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },
  '& .MuiTabs-flexContainer': {
    borderBottom: '1px solid #DDE5F2',
    height: '44px'
  }
}));

export const InputLabelWrapper = styled(Box)(() => ({
  position: 'absolute',
  top: '-15%',
  right: 0,
  margin: '8px'
}));

export const SaveButtonText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'bordered'
})(({ bordered = false }: StyledTabProps) => ({
  fontSize: '13px',
  fontWeight: 400,
  borderBottom: bordered ? '1px solid' : '1px solid gray',
  cursor: 'pointer',
  color: bordered ? 'primary' : 'GrayText'
}));

export const CustomEditActionCell = (props: TableEditColumn.CellProps) => {
  return (
    <EditActionCell
      style={{
        height: '36px',
        padding: '8px 8px',
        wordWrap: 'break-word',
        whiteSpace: 'unset'
      }}
      {...props}
    />
  );
};

export const CompactTableCellHeader = (props) => {
  return (
    <TableCellHeader
      {...props}
      style={{
        padding: '8px'
      }}
    />
  );
};

export const StyledChip = styled(Chip)(() => ({
  minWidth: 36,
  borderRadius: '6px',
  height: 'fit-content',
  padding: '3px 0',
  display: 'inline-flex',
  justifyContent: 'center',
  backgroundColor: '#193A6B0F'
}));

export const StyledLinkButton = styled(ButtonBase)(() => ({
  cursor: 'pointer',
  marginTop: '8px',
  borderBottom: '1px solid #000c57',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 0,
  padding: '2px 0px',
  ":disabled": {
    opacity: '0.3'
  }
}));

export const StyledTextarea = styled('textarea')(
  ({ theme }) => `
  padding: 9px;
  width: 100%;
  border-radius: 8px;
  resize: none;
  font: inherit;
  color: currentColor;
  outline: none;
  box-sizing: border-box;
  border-color: ${theme.colors.alpha.black[30]};

  :focus {
    outline: 2px solid ${theme.colors.primaryAlt.main};
    outline-offset: -2px;
    border-color: ${theme.colors.primaryAlt.main};
  }

  :hover {    
    border-color: ${theme.colors.primaryAlt.main};
  }

  ::placeholder {
    color: ${theme.colors.alpha.black[50]}
  }

  &.invalid {
    border-color: ${theme.colors.error.main};
  }

  &.invalid:focus {
    outline-color: ${theme.colors.error.main};
    outline-width: 1.5px;
    outline-offset: -1.5px;
  }
`
);

export const CharacterLimitInfo = styled(Typography)(`
  position: absolute;
  bottom: -10px;
  right: 16px;
  font-size: 10px;
  pointer-events: none;
  text-transform: lowercase;
`);

export const StyledSecondaryToggleButton = styled(ToggleButton)(
  ({ theme }) => ({
    background: 'unset',
    color: `${theme.colors.primary.main} !important`,
    ':hover': {
      backgroundColor: alpha(theme.colors.secondary.main, 0.1)
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: alpha(theme.colors.secondary.main, 0.2)
    }
  })
);