import { Getter } from '@devexpress/dx-react-core';
import {
  ChangeSet,
  CustomPaging,
  DataTypeProvider,
  EditingState,
  PagingState,
  Table
} from '@devexpress/dx-react-grid';
import {
  PagingPanel,
  TableEditColumn,
  Grid as TableGrid,
  TableHeaderRow,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import {
  CustomTableRow,
  Root,
  TableRowCell,
  TableRowHeader
} from 'src/components/Grid/styles';
import { GridContainer } from 'src/components/GridContainer';
import Loader from 'src/components/Loader';
import { getColumnExtensionsWidth } from 'src/content/Documents/utils/utils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import {
  useDeleteDatasetMutation,
  useLazyGetDatasetsQuery
} from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { CompactTableCellHeader, CustomEditActionCell } from '../../styles';
import {
  DATASET_COLUMNS,
  PAGE_SIZES,
  datasetColumnExtensions,
  datasetColumns,
  getActionColumnSorted,
  getRowId
} from '../../utils/utils';
import Command from './RowButtons';
import { debounce } from 'lodash';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import ImportDataset from '../ImportDataset';
import { StringKeys } from 'src/types/base';
import format from 'date-fns/format';
import { FilesCountFormatter } from '../../utils/ColumnFormatters';
import Filters from './Filters';
import DatasetDetailsModal from '../DatasetDetailsModal';
import GridSkeleton from 'src/components/GridSkeleton';

function DatasetList() {
  const { t, i18n } = useTranslation();
  const [deletedRowId, setDeletedRowId] = useState<string | number>();
  const [detailsRow, setDetailsRow] = useState<any>();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const { sidebarToggle } = useContext(SidebarContext);
  const [columnExtensions, setColumnExtensions] = useState<any[]>([]);
  const gridRef = useRef<HTMLDivElement>(null);
  const [isImportFromAppOpen, setIsImportFromAppOpen] = useState(false);
  const [sources, setSources] = useState<StringKeys>({});

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZES[0]);
  const [search, setSearch] = useState<string>('');
  const [dateCreated, setDateCreated] = useState<Date>(null);

  const [fetchDatasets, { isLoading, isFetching, data }] = useLazyQuery({
    api: useLazyGetDatasetsQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [deleteDataset] = useMutation({
    api: useDeleteDatasetMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const debouncedFetchDatasets = useCallback(debounce(fetchDatasets, 300), [
    fetchDatasets
  ]);

  useEffect(() => {
    debouncedFetchDatasets({
      params: {
        params: {
          p: page,
          ps: pageSize,
          s: search,
          created_date: dateCreated ? format(dateCreated, 'yyyy-MM-dd') : null
        }
      }
    });
  }, [page, pageSize, search, dateCreated]);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    setPage(0);
  }, []);

  useEffect(() => {
    setColumnExtensions(
      getColumnExtensionsWidth(
        datasetColumnExtensions,
        gridRef.current?.clientWidth || 0
      )
    );
  }, [sidebarToggle, gridRef.current]);

  const translatedDatasetColumns = useMemo(
    () =>
      datasetColumns.map((col) => ({
        ...col,
        title: t(col.title)
      })),
    [i18n.language]
  );

  const onCommitChanges = ({ deleted }: ChangeSet) => {
    if (deleted) {
      setDeletedRowId(deleted[0]);
      setIsDeleteOpen(true);
      return;
    }
  };

  const handleClose = useCallback(() => {
    setIsDeleteOpen(false);
    setDeletedRowId(undefined);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleteOpen(false);
    deleteDataset({
      params: {
        params: {
          id: deletedRowId
        }
      },
      successMsg: T.datasetDeleteSuccess,
      fallbackMsg: T.datasetDeleteFailed
    });
  }, [deletedRowId]);

  const TableRow = useCallback(({ row, ...restProps }: Table.DataRowProps) => {
    const handleRowClick = () => {
      setDetailsRow(row);
    };
    return <CustomTableRow row={row} {...restProps} onClick={handleRowClick} />;
  }, []);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleImportFromApp = useCallback(() => {
    setIsImportFromAppOpen(true);
  }, []);

  const handleImportFromAppClose = useCallback(() => {
    setIsImportFromAppOpen(false);
    setSources({});
  }, []);

  const handleDetailsModalClose = useCallback(() => {
    setDetailsRow(null);
  }, []);

  const clearAllFilters = useCallback(() => {
    setSearch('');
    setDateCreated(null);
  }, []);

  return (
    <>
      <Box
        maxWidth="100%"
        display="flex"
        flexDirection="column"
        rowGap="16px"
        width="100%"
      >
        <Filters
          search={search}
          setSearch={setSearch}
          dateCreated={dateCreated}
          setDateCreated={setDateCreated}
          clearAllFilters={clearAllFilters}
          handleImportFromApp={handleImportFromApp}
        />

        <Box maxWidth="100%" height="100%" ref={gridRef}>
          <GridContainer minHeight="100%" length={1}>
            {(!isFetching && !data?.data) || isFetching ? (
              <GridSkeleton
                columnExtensions={columnExtensions}
                editorColumn
                sxTableHeaderCell={{ padding: '8px' }}
                sxTableCell={{
                  height: '36px',
                  padding: '16px 8px'
                }}
              />
            ) : (
              <TableGrid
                rows={data?.data || []}
                columns={translatedDatasetColumns}
                getRowId={getRowId}
                rootComponent={Root}
              >
                <DataTypeProvider
                  for={[DATASET_COLUMNS.FILES_COUNT]}
                  formatterComponent={FilesCountFormatter}
                />

                <VirtualTable
                  rowComponent={TableRow}
                  cellComponent={TableRowCell}
                  columnExtensions={columnExtensions}
                />
                <TableHeaderRow
                  rowComponent={TableRowHeader}
                  cellComponent={CompactTableCellHeader}
                />

                <PagingState
                  currentPage={page}
                  onCurrentPageChange={setPage}
                  pageSize={pageSize}
                  onPageSizeChange={handlePageSizeChange}
                />
                <CustomPaging totalCount={data?.rc} />
                <PagingPanel
                  pageSizes={PAGE_SIZES}
                  messages={{
                    rowsPerPage: t(T.rowsPerPage)
                  }}
                />

                <EditingState onCommitChanges={onCommitChanges} />
                <TableEditColumn
                  showDeleteCommand
                  commandComponent={Command}
                  cellComponent={CustomEditActionCell}
                />

                <Getter name="tableColumns" computed={getActionColumnSorted} />
              </TableGrid>
            )}
          </GridContainer>
        </Box>
      </Box>

      <DocumentsContextProvider>
        {isImportFromAppOpen && (
          <ImportDataset
            open={isImportFromAppOpen}
            handleClose={handleImportFromAppClose}
            sources={sources}
          />
        )}
      </DocumentsContextProvider>

      {isDeleteOpen && (
        <ConfirmationDialog
          message={T.datasetDeleteConfirmation}
          onClose={handleClose}
          onConfirm={handleDelete}
          isOpen={isDeleteOpen}
        />
      )}

      {detailsRow && (
        <DatasetDetailsModal
          dataset={detailsRow}
          isOpen={!!detailsRow}
          onClose={handleDetailsModalClose}
        />
      )}
    </>
  );
}

export default DatasetList;
