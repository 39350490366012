import {
  GroupsOutlined,
  InfoOutlined,
  PersonOutlineOutlined
} from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@mui/material';
import React, { ChangeEvent, Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import Loader from '../Loader';
import { CONTENT_SHARED_WITH_TYPE } from 'src/types/enum';

interface SharedWithProps {
  handleSearch: (query: string) => void;
  disclaimer?: string;
  isLoading: boolean;
  noResultsMessage: string;
  noSearchResultsMessage: string;
  results: any[];
  getPrimaryText: (user: any) => string;
  getSecondaryText: (user: any) => string;
  ListItemInfo?: React.FC<{ user: any }>;
  keyField?: string;
}

const SharedWith = ({
  handleSearch,
  disclaimer,
  isLoading = true,
  noResultsMessage = '',
  noSearchResultsMessage = '',
  results,
  getPrimaryText,
  getSecondaryText,
  ListItemInfo,
  keyField = 'id'
}: SharedWithProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const handleSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearch(e.target.value);
      handleSearch(e.target.value);
    },
    []
  );

  return (
    <>
      <Box
        sx={(theme) => ({
          border: `1px solid ${theme.colors.primaryAlt.main}`,
          padding: theme.spacing(1)
        })}
      >
        <TextField
          size="small"
          placeholder={t(T.search)}
          fullWidth
          value={search}
          onChange={handleSearchChange}
        />
      </Box>

      {isLoading ? (
        <Box
          sx={(theme) => ({
            padding: 5,
            paddingTop: '50px',
            border: `1px solid ${theme.colors.primaryAlt.main}`,
            borderTop: 'none',
            height: 'calc(100% - 88px)'
          })}
        >
          <Loader />
        </Box>
      ) : !results?.length ? (
        <Box
          sx={(theme) => ({
            textAlign: 'center',
            margin: 'auto',
            border: `1px solid ${theme.colors.primaryAlt.main}`,
            borderTop: 'none',
            height: 'calc(100% - 88px)',
            paddingTop: '60px'
          })}
        >
          <Typography variant="h6">
            {t(search ? noSearchResultsMessage : noResultsMessage)}
          </Typography>
        </Box>
      ) : (
        <List
          sx={(theme) => ({
            padding: 0,
            overflow: 'auto',
            height: `calc(100% - ${disclaimer ? 88 : 60}px)`, // TODO: use css better
            border: `1px solid ${theme.colors.primaryAlt.main}`,
            borderTop: 'none'
          })}
        >
          {results?.map((user) => (
            <Fragment key={user[keyField]}>
              <ListItem>
                <ListItemIcon sx={{ minWidth: 'unset', paddingRight: '16px' }}>
                  {user.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP ? (
                    <GroupsOutlined />
                  ) : (
                    <PersonOutlineOutlined />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={getPrimaryText(user)}
                  secondary={
                    getSecondaryText ? (
                      <span style={{ fontSize: '13px' }}>
                        {getSecondaryText(user)}
                      </span>
                    ) : undefined
                  }
                />

                {ListItemInfo && <ListItemInfo user={user} />}
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      )}

      {disclaimer && (
        <Grid
          container
          direction="row"
          sx={{ alignItems: 'center', paddingTop: '4px' }}
        >
          <InfoOutlined fontSize="small" />
          <Typography
            color="#757575"
            sx={{ fontSize: '13px', paddingLeft: '4px' }}
          >
            {t(disclaimer)}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default SharedWith;
