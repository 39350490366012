import { format } from 'date-fns';
import { debounce } from 'lodash';
import { useCallback, useEffect } from 'react';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { useLazyGetQuestionnaireSharedUsersQuery } from 'src/services/api';
import { DATE_FORMAT } from '../../utils/utils';
import SharedWith from 'src/components/ShareModal/SharedWith';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const SentTo = ({ id }) => {
  const [fetchSharedUsers, { data, isLoading }] = useLazyQuery({
    api: useLazyGetQuestionnaireSharedUsersQuery
  });

  useEffect(() => {
    fetchSharedUsers({
      params: {
        params: {
          questionnaire_id: id
        }
      }
    });
  }, []);

  const handleSearch = useCallback(
    debounce((searchQuery: string) => {
      fetchSharedUsers({
        params: {
          params: {
            questionnaire_id: id,
            s: searchQuery
          }
        }
      });
    }, 300),
    []
  );

  const getPrimaryText = useCallback((user) => user.username, []);
  const getSecondaryText = useCallback(
    (user) => format(new Date(user.created_on), DATE_FORMAT),
    []
  );

  const sharedUsers: any[] = data?.data || [];

  return (
    <SharedWith
      handleSearch={handleSearch}
      isLoading={isLoading}
      noResultsMessage={T.questionnaireNotSharedYet}
      noSearchResultsMessage={T.noMatchingUsersOrUserGroupsFound}
      results={sharedUsers}
      getPrimaryText={getPrimaryText}
      getSecondaryText={getSecondaryText}
    />
  );
};

export default SentTo;
