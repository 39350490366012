import React from 'react';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { SxProps } from '@mui/system';

const skeletonRowsNumber = 5;
type GridSkeletonProps = {
  columnExtensions: {
    columnName: string;
    width?: string | number;
    title?: string;
  }[];
  editorColumn?: boolean;
  sxTableHeaderCell?: SxProps;
  sxTableCell?: SxProps;
};
const GridSkeleton = ({
  columnExtensions,
  editorColumn = true,
  sxTableHeaderCell = {},
  sxTableCell = {}
}: GridSkeletonProps) => {
  const { t, i18n }: { t: any; i18n: any } = useTranslation();
  const theme = useTheme();
  return (
    <TableContainer
      sx={{
        minHeight: '380px'
      }}
    >
      <Table>
        <TableHead
          sx={{
            '& :hover': {
              backgroundColor: '#cad9f233'
            },
            borderBottom: `1px solid ${theme.colors.alpha.black[10]}`
          }}
        >
          <TableRow
            sx={{
              th: {
                backgroundColor: '#cad9f233',
                border: 'none',
                color: theme.colors.primary.main
              }
            }}
          >
            {columnExtensions.map((item, index) => {
              return (
                <TableCell
                  key={index}
                  sx={{
                    fontSize: '13px',
                    fontWeight: 700,
                    width: item?.width || '100%',
                    ...(index === 0 && { paddingLeft: '50px' }),
                    ...sxTableHeaderCell
                  }}
                >
                  {t(item.title || T[item.columnName])}
                </TableCell>
              );
            })}
            {editorColumn && (
              <TableCell
                colSpan={1}
                sx={{
                  minWidth: 120
                }}
              ></TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(skeletonRowsNumber)].map((row, index) => {
            return (
              <TableRow key={index}>
                {columnExtensions.map((item, index) => {
                  return (
                    <TableCell
                      key={index}
                      sx={{
                        ...(index === 0 && { paddingLeft: '50px' }),
                        ...sxTableCell
                      }}
                    >
                      <Skeleton animation="wave" />
                    </TableCell>
                  );
                })}
                {editorColumn && <TableCell></TableCell>}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GridSkeleton;
