import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Popover,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { StringKeys } from 'src/types/base';

import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { ReactComponent as MoreIcon } from 'src/assets/documentIcons/more-icon.svg';
import { ReactComponent as RenameIcon } from 'src/assets/documentIcons/rename-icon.svg';
import { ReactComponent as ShareIcon } from 'src/assets/documentIcons/share-icon.svg';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import { ReactComponent as WarningIcon } from 'src/assets/assistantIcons/Warning.svg';
import { ReactComponent as StarOutlineIcon } from 'src/assets/documentIcons/star-icon.svg';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { DEFAULT_CONTENT_ROLES, FAVOURITE_TYPE } from 'src/types/enum';
import { ActionButton, StarRateIcon } from '../../Documents/utils/styles';
import { getCardIconsStyles } from '../../Documents/Workspaces/WorkspaceCard/styles';

interface CollectionCardProps {
  row: StringKeys;
  icons: any[];
  handleFetchAssistants: (fetch_all: boolean) => void;
  handleCreateModalOpen: (row: any) => void;
  handleStartChatClick: (data: StringKeys) => void;
  handleShareResourceClick?: (data: StringKeys) => void;
  setDeletedRowId?: React.Dispatch<React.SetStateAction<string>>;
  setDeleteOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollectionCard = ({
  row,
  icons,
  handleShareResourceClick,
  handleStartChatClick,
  setDeletedRowId,
  setDeleteOpen,
  handleCreateModalOpen
}: CollectionCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { updateFavourite, isUpdatingFavourite } = useContext(DocumentsContext);
  const { isFavouritesFetching, configuredModels } = useSelector(
    (state: RootState) => state.data
  );

  const open = Boolean(anchorEl);
  const id = open ? 'more-actions' : undefined;

  const rowData: StringKeys = useMemo(() => {
    return {
      ...row,
      aiModel: configuredModels.filter(
        (model) => model.id === parseInt(row?.settings.model_id)
      )[0]
    };
  }, [row]);

  const hasPermission = useMemo(
    () =>
      !(
        (rowData?.has_permission ||
          rowData?.settings?.web_search_enabled ||
          rowData?.settings?.world_knowledge) &&
        rowData?.aiModel
      ),
    [rowData]
  );

  const hasPermissions = useMemo(
    () => row?.permission?.role === DEFAULT_CONTENT_ROLES.CONTROLLER || false,
    [row]
  );

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleStartChat = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleStartChatClick(rowData);
  };
  const handleUpdateAssistant = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClose(e);
    handleCreateModalOpen(row);
  };

  const handleShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleShareResourceClick?.(row);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    handleClose(e);
    setDeletedRowId?.(row?.id);
    setDeleteOpen?.(true);
  };

  const handleAddFavourite = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!isUpdatingFavourite && !isFavouritesFetching) {
      dispatch(setIsFavouritesFetching(true));
      updateFavourite(row?.id, !row?.isFavourite, FAVOURITE_TYPE.ASSISTANT);
    }
  };

  const handleAssistantClick = useCallback(() => {
    handleStartChatClick(rowData);
  }, [rowData]);

  return (
    <>
      <Card
        onClick={handleAssistantClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          boxShadow: 'none',
          border: `1px solid ${alpha(theme.colors.custom.labelColor, 0.2)}`,
          borderRadius: '6px !important',
          padding: '8px 12px',
          cursor: 'pointer',
          minHeight: '90px',
          maxWidth: '100%',
          ['& .collection-card-actions']: {
            visibility: 'hidden'
          },
          '& .starRateIcon': {
            visibility: row?.isFavourite ? 'visible' : 'hidden'
          },

          ['&:hover']: {
            ['& .collection-card-actions']: {
              visibility: 'visible',
              display: 'flex'
            },
            boxShadow: theme.colors.shadows.cardElevation
          }
        }}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          minWidth={'0'}
          flex={1}
          overflow={'hidden'}
        >
          {row?.settings?.icon_id ? (
            <img
              src={
                icons.filter(
                  (icon) => icon.id === parseInt(row?.settings?.icon_id)
                )?.[0]?.icon
              }
              alt="Assistant-Icon"
              style={{
                minWidth: '70px',
                maxWidth: '70px',
                height: '70px',
                borderRadius: '10%'
              }}
            />
          ) : (
            <DefaultIcon
              style={{
                minWidth: '70px',
                maxWidth: '70px',
                height: '70px',
                borderRadius: '10%',
                padding: '10px',
                backgroundColor: 'rgba(222, 224, 229, 0.70)'
              }}
            />
          )}
          <Box
            sx={{
              marginLeft: 1,
              overflow: 'hidden',
              maxWidth: '100%'
            }}
          >
            <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
              <Typography
                variant={'body1'}
                noWrap={true}
                style={{
                  marginRight: 1,
                  fontSize: '16px',
                  fontWeight: 500,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {row?.name}
              </Typography>
              {hasPermission && <WarningIcon style={{ marginTop: '-3px' }} />}
            </Box>
            <Typography
              style={{
                padding: 0,
                fontSize: '11px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis'
              }}
            >
              {row?.settings?.description}
            </Typography>
            <Box>
              <Typography
                style={{
                  padding: 0,
                  fontSize: '11px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {`${t(T.creator)}: ${row?.username}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Grid
          display={'flex'}
          alignItems={'flex-end'}
          flexDirection={'column'}
          paddingTop={0.75}
          justifyContent={'space-evenly'}
          className="collection-card-actions"
        >
          <Grid item minHeight={'30px'}>
            {row?.id && (
              <Button
                title={t(T.startChat)}
                onClick={handleStartChat}
                color="secondary"
                size="small"
                variant="contained"
                sx={{
                  fontSize: '12px',
                  px: 0.8,
                  py: 0.5
                }}
              >
                {t(T.startChat)}
              </Button>
            )}
          </Grid>
          <Grid item minHeight={'30px'}>
            <Grid
              container
              display={'flex'}
              justifyItems={'flex-end'}
              alignContent={'flex-end'}
              alignSelf={'flex-end'}
              marginTop={0.5}
            >
              <Grid item>
                <>
                  {hasPermissions && (
                    <IconButton
                      sx={{
                        padding: '5px 5px'
                      }}
                      aria-describedby={id}
                      onClick={handleClick}
                      title={t(T.moreActions)}
                    >
                      <MoreIcon />
                    </IconButton>
                  )}
                  {hasPermissions && (
                    <IconButton
                      onClick={handleShare}
                      title={t(T.share)}
                      sx={{
                        padding: '5px 5px'
                      }}
                    >
                      <ShareIcon />
                    </IconButton>
                  )}
                  <IconButton
                    title={
                      row?.isFavourite
                        ? t(T.unmarkFavourite)
                        : t(T.markFavourite)
                    }
                    onClick={handleAddFavourite}
                    sx={getCardIconsStyles(false)}
                    size="small"
                  >
                    {row?.isFavourite ? (
                      <StarRateIcon className="starRateIcon" />
                    ) : (
                      <StarOutlineIcon />
                    )}
                  </IconButton>
                  {hasPermissions && (
                    <Popover
                      id={id}
                      open={open}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      disablePortal={true}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignContent={'flex-start'}
                        justifyContent={'flex-start'}
                        textAlign={'left'}
                        sx={{
                          backgroundColor: 'white'
                        }}
                      >
                        {
                          <ActionButton onClick={handleUpdateAssistant}>
                            <RenameIcon />
                            <Typography
                              sx={{
                                marginLeft: 1,
                                color: theme.colors.custom.labelColor
                              }}
                            >
                              {t(T.edit)}
                            </Typography>
                          </ActionButton>
                        }
                        {
                          <ActionButton onClick={handleDelete}>
                            <DeleteIcon />
                            <Typography
                              sx={{
                                marginLeft: 1,
                                color: theme.colors.custom.labelColor
                              }}
                            >
                              {t(T.delete)}
                            </Typography>
                          </ActionButton>
                        }
                      </Box>
                    </Popover>
                  )}
                </>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default CollectionCard;
