import { Getter } from '@devexpress/dx-react-core';
import {
  ChangeSet,
  CustomPaging,
  EditingState,
  PagingState
} from '@devexpress/dx-react-grid';
import {
  PagingPanel,
  TableEditColumn,
  Grid as TableGrid,
  TableHeaderRow,
  VirtualTable
} from '@devexpress/dx-react-grid-material-ui';
import { Box } from '@mui/material';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationDialog from 'src/components/ConfirmationDialog';
import {
  CustomTableRow,
  Root,
  TableRowCell,
  TableRowHeader
} from 'src/components/Grid/styles';
import GridContainer from 'src/components/GridContainer';
import Loader from 'src/components/Loader';
import { getColumnExtensionsWidth } from 'src/content/Documents/utils/utils';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import {
  addUpdateAnalysisHistoryContext,
  openAnalysisDetails,
  setInitiateAnalysisOpen,
  updateInitiateAnalysisState
} from 'src/redux/slices/docAnalyst';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import {
  useDeleteAnalysisMutation,
  useLazyGetAnalysisHistoryQuery,
  useLazyGetAnalysisQuery
} from 'src/services/api';
import { CommandComponentProps } from 'src/types/custom_component';
import { DOCUMENT_ANALYSIS_STATUS } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { CompactTableCellHeader, CustomEditActionCell } from '../../styles';
import { exportAnalysis } from '../../utils/exportAnalysis';
import {
  ANALYSIS_COLUMNS,
  PAGE_SIZES,
  analysisColumnExtensions,
  analysisColumns,
  analysisSearchFields,
  getActionColumnSorted,
  getRowId
} from '../../utils/utils';
import RowAction from './RowButtons';
import { StatusProvider } from './StatusProvider';
import keyBy from 'lodash/keyBy';
import { AzureWebPubSubUserContext } from 'src/contexts/AzureWebPubSubUserContext';
import format from 'date-fns/format';
import Filters from './Filters';
import GridSkeleton from 'src/components/GridSkeleton';

function AnalysisHistory() {
  const { t, i18n } = useTranslation();
  const [deletedRowId, setDeletedRowId] = useState<string | number>();
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [columnExtensions, setColumnExtensions] = useState<any[]>([]);
  const gridRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const { sidebarToggle } = useContext(SidebarContext);
  const { runningAnalysisData } = useContext(AzureWebPubSubUserContext);

  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZES[0]);
  const [search, setSearch] = useState<string>('');
  const [searchField, setSearchField] = useState<string>(
    ANALYSIS_COLUMNS.ANALYSIS
  );
  const [dateCreated, setDateCreated] = useState<Date>(null);
  const [status, setStatus] = useState<any>(null);

  const [fetchAnalysisHistory, { isLoading, isFetching, data }] = useLazyQuery({
    api: useLazyGetAnalysisHistoryQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [deleteAnalysis] = useMutation({
    api: useDeleteAnalysisMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [fetchAnalysis] = useLazyQuery({
    api: useLazyGetAnalysisQuery
  });

  const {
    docAnalyst: { currentlyRunningAnalysis, analysisHistoryContexts },
    auth: { maxAnalysisLimit }
  } = useSelector((state: RootState) => state);

  const [currentRows, setCurrentRows] = useState([]);

  useEffect(() => {
    fetchAnalysisHistory({
      params: {
        params: {
          p: page,
          ps: pageSize,
          s: search,
          search_by: searchField,
          created_date: dateCreated ? format(dateCreated, 'yyyy-MM-dd') : null,
          status_id: status?.id
        }
      },
      successCallback(data) {
        setCurrentRows(data?.data);
      }
    });
  }, [page, pageSize, search, searchField, dateCreated, status]);

  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPageSize(pageSize);
    setPage(0);
  }, []);

  useEffect(() => {
    setColumnExtensions(
      getColumnExtensionsWidth(
        analysisColumnExtensions,
        gridRef.current?.clientWidth || 0
      )
    );
  }, [sidebarToggle, gridRef.current]);

  const translatedAnalysisColumns = useMemo(
    () =>
      analysisColumns.map((col) => ({
        ...col,
        title: t(col.title)
      })),
    [i18n.language]
  );

  const analysisIdMap = useMemo(() => {
    if (!Array.isArray(data?.data)) return {};
    return data.data.reduce((acc, item, index) => {
      acc[item.id] = { ...item, index };
      return acc;
    }, {});
  }, [data]);

  const updateAnalysisStreamingStatusOnDataMount = useCallback(
    (analysisMap: { [key: string]: any }) => {
      Object.entries(analysisMap).forEach(([analysisId, analysis]) => {
        if (
          analysis.status_code === DOCUMENT_ANALYSIS_STATUS.INPROGRESS ||
          analysis.status_code === DOCUMENT_ANALYSIS_STATUS.REGENERATING
        ) {
          dispatch(
            addUpdateAnalysisHistoryContext({
              analysisId: parseInt(analysisId),
              isAnalysisStreaming: true
            })
          );
        } else if (
          analysisHistoryContexts[parseInt(analysisId)]?.isAnalysisStreaming &&
          analysis.status_code !== DOCUMENT_ANALYSIS_STATUS.INPROGRESS &&
          analysis.status_code !== DOCUMENT_ANALYSIS_STATUS.REGENERATING
        ) {
          dispatch(
            addUpdateAnalysisHistoryContext({
              analysisId: parseInt(analysisId),
              isAnalysisStreaming: false
            })
          );
        }
      });
    },
    [analysisHistoryContexts]
  );

  const updateAnalysisStatusOnEventCallback = useCallback(() => {
    if (
      runningAnalysisData?.status !== DOCUMENT_ANALYSIS_STATUS.INPROGRESS &&
      runningAnalysisData?.id in analysisIdMap
    ) {
      let index = analysisIdMap[runningAnalysisData.id].index;
      setCurrentRows((prevRows) => {
        const updatedRows = [...prevRows];
        updatedRows.splice(index, 1, {
          ...updatedRows[index],
          status_code:
            runningAnalysisData?.status || updatedRows[index].status_code
        });
        return updatedRows;
      });
    }
  }, [analysisIdMap, runningAnalysisData]);

  useEffect(() => {
    updateAnalysisStreamingStatusOnDataMount(analysisIdMap);
  }, [analysisIdMap]);

  useEffect(() => {
    updateAnalysisStatusOnEventCallback();
  }, [runningAnalysisData?.status]);

  const handleExport = useCallback((analysis) => {
    fetchAnalysis({
      params: {
        params: {
          id: analysis.id
        }
      },
      successCallback: (data) => {
        const detail = data?.data[0]?.detail || [];
        exportAnalysis(
          analysis.name,
          analysis.dataset_name,
          analysis.questionnaire_name,
          detail
        );
      }
    });
  }, []);

  const Command = useCallback((props: CommandComponentProps) => {
    return <RowAction {...props} handleExport={handleExport} />;
  }, []);

  const onCommitChanges = ({ deleted }: ChangeSet) => {
    if (deleted) {
      setDeletedRowId(deleted[0]);
      setIsDeleteOpen(true);
      return;
    }
  };

  const handleClose = useCallback(() => {
    setIsDeleteOpen(false);
    setDeletedRowId(undefined);
  }, []);

  const handleDelete = useCallback(() => {
    setIsDeleteOpen(false);
    deleteAnalysis({
      params: {
        params: {
          id: deletedRowId
        }
      },
      successMsg: T.analysisDeleteSuccess,
      fallbackMsg: T.analysisDeleteFailed,
      successCallback() {
        dispatch(
          addUpdateAnalysisHistoryContext({
            analysisId: deletedRowId as number,
            isAnalysisStreaming: false
          })
        );
      }
    });
  }, [deletedRowId]);

  const TableRow = useCallback(({ row, ...restProps }: any) => {
    const onClickRow = (e: any) => {
      dispatch(
        openAnalysisDetails({
          selectedAnalysis: { id: row.id, status: row.status_code },
          title: row.name,
          selectedDataset: {
            id: row.dataset_id,
            name: row.dataset_name,
            isDatasetDeleted: row.is_dataset_deleted || row.is_dataset_corrupted
          },
          selectedQuestionnaire: {
            id: 0,
            name: row.questionnaire_name,
            isEditable: row.is_editable
          },
          modelId: row.model_id
        })
      );
    };
    return <CustomTableRow row={row} {...restProps} onClick={onClickRow} />;
  }, []);

  const handleNewAnalysis = () => {
    dispatch(setInitiateAnalysisOpen(true));
  };

  const clearAllFilters = useCallback(() => {
    setSearch('');
    setSearchField(ANALYSIS_COLUMNS.ANALYSIS);
    setDateCreated(null);
    setStatus(null);
  }, []);

  const isInitiateAnalysisDisabled = useMemo(
    () => currentlyRunningAnalysis === maxAnalysisLimit,
    [maxAnalysisLimit, currentlyRunningAnalysis]
  );

  return (
    <>
      <Box
        maxWidth="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        rowGap="16px"
        width="100%"
      >
        <Filters
          search={search}
          setSearch={setSearch}
          searchField={searchField}
          setSearchField={setSearchField}
          searchFields={analysisSearchFields}
          dateCreated={dateCreated}
          setDateCreated={setDateCreated}
          clearAllFilters={clearAllFilters}
          handleNewAnalysis={handleNewAnalysis}
          isAnalysisDisabled={isInitiateAnalysisDisabled}
          status={status}
          setStatus={setStatus}
        />

        <Box maxWidth="100%" height="100%" ref={gridRef}>
          <GridContainer minHeight="100%" length={1}>
            {(!isFetching && !data?.data) || isFetching ? (
              <GridSkeleton
                columnExtensions={columnExtensions}
                editorColumn
                sxTableHeaderCell={{ padding: '8px' }}
                sxTableCell={{
                  height: '36px',
                  padding: '16px 8px',
                }}
              />
            ) : (
              <TableGrid
                rows={currentRows}
                columns={translatedAnalysisColumns}
                getRowId={getRowId}
                rootComponent={Root}
              >
                <StatusProvider for={[ANALYSIS_COLUMNS.STATUS]} />

                <VirtualTable
                  rowComponent={TableRow}
                  cellComponent={TableRowCell}
                  columnExtensions={columnExtensions}
                />
                <TableHeaderRow
                  rowComponent={TableRowHeader}
                  cellComponent={CompactTableCellHeader}
                />

                <EditingState onCommitChanges={onCommitChanges} />
                <TableEditColumn
                  showEditCommand
                  showDeleteCommand
                  commandComponent={Command}
                  cellComponent={CustomEditActionCell}
                />

                <PagingState
                  currentPage={page}
                  onCurrentPageChange={setPage}
                  pageSize={pageSize}
                  onPageSizeChange={handlePageSizeChange}
                />
                <CustomPaging totalCount={data?.rc} />
                <PagingPanel
                  pageSizes={PAGE_SIZES}
                  messages={{
                    rowsPerPage: t(T.rowsPerPage)
                  }}
                />

                <Getter name="tableColumns" computed={getActionColumnSorted} />
              </TableGrid>
            )}
          </GridContainer>
        </Box>
      </Box>

      {isDeleteOpen && (
        <ConfirmationDialog
          message={T.analysisDeleteConfirmation}
          onClose={handleClose}
          onConfirm={handleDelete}
          isOpen={isDeleteOpen}
        />
      )}
    </>
  );
}

export default AnalysisHistory;
