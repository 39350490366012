import { Box, Card, CardContent, Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';
import { RootState, useSelector } from 'src/redux/store';
import { QuestionCardText, StyledQuestionCard } from '../../styles';

type Props = {
  message: string;
  index?: number;
  handleQuestionCardClick?: (index: number) => void;
};

const QuestionCard = ({ message, index, handleQuestionCardClick }: Props) => {
  const { assistantContext } = useSelector(
    (state: RootState) => state.assistant
  );

  const handleCardClick = useCallback(() => {
    if (
      assistantContext?.isAssistant &&
      (index != null || index !== undefined) &&
      handleQuestionCardClick
    ) {
      handleQuestionCardClick(index);
    }
  }, [index, handleQuestionCardClick, assistantContext?.isAssistant]);

  return (
    <Tooltip title={assistantContext?.isAssistant ? message : ''}>
      <StyledQuestionCard
        sx={{
          cursor: assistantContext?.isAssistant ? 'pointer' : 'default'
        }}
        onClick={handleCardClick}
      >
        <CardContent
          sx={{
            paddingY: '0',
            paddingBottom: '0 !important',
            textAlign: 'center'
          }}
        >
          <QuestionCardText>
            {typeof message === 'string' ? message : ''}
          </QuestionCardText>
        </CardContent>
      </StyledQuestionCard>
    </Tooltip>
  );
};

export default QuestionCard;
