import { ToggleButtonGroup as MuiToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledSecondaryToggleButton } from '../../styles';

interface ToggleButtonGroupProps {
  value: any;
  onChange: (e: any) => void;
  options: {
    value: string;
    label: string;
  }[];
}

const ToggleButtonGroup = ({
  value,
  onChange,
  options
}: ToggleButtonGroupProps) => {
  const { t } = useTranslation();

  return (
    <MuiToggleButtonGroup
      value={value}
      onChange={onChange}
      size="small"
      exclusive
    >
      {options.map((option) => (
        <StyledSecondaryToggleButton value={option.value} key={option.value}>
          {t(option.label)}
        </StyledSecondaryToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
};

export default ToggleButtonGroup;
