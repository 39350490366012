import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';

interface LabelProps {
  color?:
    | 'primary'
    | 'black'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'success'
    | 'info';
  children?: ReactNode;
  toolTipText?: string;
  showEllipses?: boolean;
  maxWidth?: string;
}

const LabelWrapper = styled('span')<LabelProps>(
  ({ theme, showEllipses, maxWidth = '150px' }) => `
    background-color: rgba(0,0,0,0.07);
    padding: ${theme.spacing(0.5, 1)};
    font-size: 12px;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-height: ${theme.spacing(5)};
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.5px;    
    max-width: ${maxWidth};

    &.MuiLabel {
     
      &-primary {
        background-color: ${theme.colors.primary.lighter};
        color: ${theme.palette.primary.main}
      }

      &-black {
        background-color: ${theme.colors.alpha.black[100]};
        color: ${theme.colors.alpha.white[100]};
      }
      
      &-secondary {
        background-color: ${theme.colors.secondary.lighter};
        color: ${theme.palette.secondary.main}
      }
      
      &-success {
        background-color: ${theme.colors.success.bg};
        color: ${theme.colors.custom.labelSuccess}
      }
      
      &-warning {
        background-color: ${theme.colors.warning.bg};
        color: ${theme.colors.custom.labelWarning}
      }
            
      &-error {
        background-color: ${theme.colors.error.bg};
        color: ${theme.colors.custom.labelError}
      }
      
      &-info {
        background-color: ${theme.colors.info.bg};
        color: ${theme.colors.custom.labelInfo}
      }
    }
    ${
      showEllipses &&
      `
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `
    }
  `
);

const Label: FC<LabelProps> = ({
  color,
  children,
  toolTipText,
  showEllipses,
  ...rest
}) => {
  return (
    <Tooltip title={toolTipText || ''} placement="top">
      <LabelWrapper
        className={`${color ? `MuiLabel-${color}` : ''}`}
        showEllipses={!!showEllipses}
        {...rest}
      >
        {children}
      </LabelWrapper>
    </Tooltip>
  );
};

Label.propTypes = {
  children: PropTypes.node,
  toolTipText: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'black',
    'secondary',
    'error',
    'warning',
    'success',
    'info'
  ]),
  showEllipses: PropTypes.bool
};

export default Label;
