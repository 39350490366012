import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import SentTo from './SentTo';
import ShareDialog, {
  SHARE_MODAL_TABS
} from 'src/components/ShareModal/ShareDialog';
import { useSelector } from 'src/redux/store';
import { useCallback, useState } from 'react';
import useMutation from 'src/hooks/useMutation';
import { useSendQuestionnaireMutation } from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import ShareSearchableAutoComplete from 'src/components/ShareModal/SearchableAutoComplete';
import { isEmailValid } from 'src/utils/utils';
import { Typography } from '@mui/material';

type SendQuestionnaireModalProps = {
  id: number;
  isOpen: boolean;
  onClose: () => void;
  selectedTab: SHARE_MODAL_TABS;
  setSelectedTab: React.Dispatch<React.SetStateAction<SHARE_MODAL_TABS>>;
};

const SendQuestionnaireModal = ({
  id,
  isOpen,
  onClose,
  selectedTab,
  setSelectedTab
}: SendQuestionnaireModalProps) => {
  const { t } = useTranslation();
  const { applicationResources } = useSelector((state) => state.data);

  const [value, setValue] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [sendQuestionnaire, { isLoading }] = useMutation({
    api: useSendQuestionnaireMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const handleChange = useCallback((_, newValue) => {
    setValue(newValue);
  }, []);

  const handleConfirm = () => {
    const shareList = [];
    value.forEach((v) => {
      if (
        v.type_id == applicationResources.User ||
        v.type_id == applicationResources['User Group']
      ) {
        shareList.push({ type_id: v.type_id, user_group: v.value });
      }
    });

    sendQuestionnaire({
      params: {
        params: {
          questionnaire_id: id,
          share_list: JSON.stringify(shareList)
        }
      },
      successMsg: T.questionnaireSendSuccess,
      successCallback() {
        onClose();
      }
    });
  };

  const validateEmail = useCallback((value) => {
    if (typeof value === 'string') {
      const isValid = isEmailValid(value);
      if (isValid) {
        setErrorMessage('');
        return true;
      } else {
        setErrorMessage(t(T.invalidEmail));
        return false;
      }
    } else if (errorMessage) {
      setErrorMessage('');
    }
    return true;
  }, []);

  return (
    <ShareDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(T.sendQuestionnaire)}
      actionButtonText={T.confirm}
      isActionButtonVisible={selectedTab === SHARE_MODAL_TABS.SEND}
      isActionButtonDisabled={value.length === 0}
      handleActionButton={handleConfirm}
      sendTabLabel={T.send}
      sentToTabLabel={T.sentHistory}
      isActionInProgress={isLoading}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      isDataLoading={false} // TODO
    >
      <>
        <ShareSearchableAutoComplete
          value={value}
          label={t(T.users)}
          onChange={handleChange}
          customValidation={validateEmail}
          freeSolo
        />
        {!!errorMessage && (
          <Typography color="error">{errorMessage}</Typography>
        )}
      </>

      <SentTo id={id} />
    </ShareDialog>
  );
};

export default SendQuestionnaireModal;
