import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Chip,
  Collapse,
  FormControlLabel,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import SearchableAutoComplete from 'src/components/SearchableAutoComplete';
import { useTranslation } from 'react-i18next';
import { useLazyGetUserOrUserGroupsQuery } from 'src/services/api';
import Select from 'src/components/Select';
import ExpandLessTwoToneIcon from '@mui/icons-material/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { ADVANCED_SHARING_OPTIONS } from '../utils/utils';
import {
  ACCOUNT_TYPE,
  CONTENT_SHARED_WITH_TYPE,
  DEFAULT_CONTENT_ROLES,
  RESOURCE_TYPE
} from 'src/types/enum';
import { GroupsOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import DocumentsContext from 'src/contexts/FileManagerContext';
import { IS_NON_AD_ENABLED } from 'src/config';
import { isEmailValid } from 'src/utils/utils';
import { RootState, useSelector } from 'src/redux/store';
import ShareSearchableAutoComplete from 'src/components/ShareModal/SearchableAutoComplete';

type InviteUsersFormProps = {
  contentRoles: any;
  formMethods: any;
  isAssistant: boolean;
};

const InviteUsersForm: React.FC<InviteUsersFormProps> = ({
  contentRoles,
  formMethods,
  isAssistant
}: InviteUsersFormProps) => {
  const { t }: { t: any; i18n: any } = useTranslation();
  const {
    control,
    watch,
    clearErrors,
    setError,
    formState: { errors }
  } = formMethods;
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const watchRole = watch('role', []);
  const watchUsers = watch('users', []);
  const { currentResourceType } = useContext(DocumentsContext);
  const [advanceSharingOptions] = useState(
    currentResourceType === RESOURCE_TYPE.NAMESPACE
      ? ADVANCED_SHARING_OPTIONS.filter((item) => item.value !== 'files-only')
      : ADVANCED_SHARING_OPTIONS
  );
  const {
    user: { isNonTrialUser }
  } = useSelector((state: RootState) => state.auth);

  const toggledAdvancedSharing = () => {
    setAdvancedOpen(!advancedOpen);
  };

  const showMessage = useMemo(() => {
    let containsRegularUsers = false;
    if (watchUsers.length && !isAssistant) {
      containsRegularUsers = watchUsers.some(
        (user) => user?.account_type?.code === ACCOUNT_TYPE.REGULAR_USER
      );
    }
    return (
      currentResourceType === RESOURCE_TYPE.NAMESPACE &&
      watchRole === DEFAULT_CONTENT_ROLES.CONTROLLER &&
      containsRegularUsers
    );
  }, [currentResourceType, watchRole, watchUsers]);

  const renderValue = useCallback(
    (value) => contentRoles?.find((_value) => _value.name === value)?.name,
    [contentRoles]
  );

  const validateEmail = useCallback(
    (value) => {
      if (typeof value === 'string') {
        const isValid = isEmailValid(value);
        if (isValid) {
          clearErrors('users');
          return true;
        } else {
          setError('users', {
            type: 'manual',
            message: t(T.invalidEmail)
          });
          return false;
        }
      } else if (errors.users) {
        clearErrors('users');
      }
      return true;
    },
    [errors]
  );

  return (
    <>
      <Grid mb={2} container spacing={1} direction={'column'}>
        <Grid item container spacing={1}>
          <Grid item xs={8}>
            <Controller
              name="users"
              control={control}
              rules={{
                required: true
              }}
              defaultValue={[]}
              render={({ field: { onChange, ...rest } }) => (
                <ShareSearchableAutoComplete
                  {...rest}
                  label={t(T.userOrUserGroup)}
                  freeSolo={IS_NON_AD_ENABLED && isNonTrialUser}
                  customValidation={validateEmail}
                  onChange={(_, value) => {
                    onChange(value);
                  }}
                />
              )}
            />
            {!!errors.users && (
              <Typography color="error">{errors.users?.message}</Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="role"
              control={control}
              rules={{
                required: true
              }}
              defaultValue={
                contentRoles?.find(
                  (item: any) => item?.name === DEFAULT_CONTENT_ROLES.VIEWER
                )?.name
              }
              render={({ field }) => (
                <Select
                  {...field}
                  valueKey={'name'}
                  width={'100%'}
                  id={'user-role'}
                  dataSource={[...contentRoles]}
                  label={t(T.role)}
                  customLabel={true}
                  renderValue={renderValue}
                />
              )}
            />
          </Grid>
        </Grid>
        {showMessage && (
          <Grid item>
            <Typography color={'error'} sx={{ fontSize: '13px' }}>
              {t(T.controllerRoleAssignmentToRegularUserAtWorkspace)}
            </Typography>
          </Grid>
        )}
      </Grid>
      <Grid container direction="column">
        {/* TODO: TO BE ADDED WHEN EMAIL FLOW WILL BE IMPLEMENTED */}
        {/* <Grid container mb={2} direction="column">
          <Grid item>
            <Typography sx={{ fontSize: theme.typography.pxToRem(14) }}>
              {t(T.message)}
            </Typography>
          </Grid>
          <Grid item mt={1}>
            <Controller
              name="message"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  placeholder={t(T.addMessage)}
                  sx={{
                    flex: 1,
                    m: 0,
                    fontSize: theme.typography.pxToRem(14),
                    '& textarea': {
                      color: theme.colors.primary.main
                    }
                  }}
                  maxRows={5}
                  minRows={3}
                  multiline
                />
              )}
            />{' '}
          </Grid>
        </Grid> */}
        <Grid item>
          {/* TODO: TO BE ADDED WHEN EMAIL FLOW WILL BE IMPLEMENTED */}
          {/* <FormGroup>
            <FormControlLabel
              control={
                <Controller
                  name="sendEmail"
                  control={control}
                  render={({ field }) => <Checkbox {...field} defaultChecked />}
                />
              }
              label={t(T.sendEmailNotificationToRecipients)}
            />
          </FormGroup> */}
          {!isAssistant && (
            <Stack spacing={1}>
              <Grid
                container
                spacing={1}
                sx={{
                  cursor: 'pointer',
                  width: '120px'
                }}
                onClick={toggledAdvancedSharing}
              >
                <Grid
                  item
                  sx={{
                    borderBottom: '1px solid #000C57',
                    height: '34px',
                    paddingLeft: '3px !important',
                    marginLeft: '9px'
                  }}
                >
                  <Typography variant="subtitle1" color="primary">
                    {t(T.advanced)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderBottom: '1px solid #000C57',
                    height: '34px'
                  }}
                >
                  {advancedOpen ? (
                    <ExpandLessTwoToneIcon color="primary" />
                  ) : (
                    <ExpandMoreTwoToneIcon color="primary" />
                  )}
                </Grid>
              </Grid>
              <Controller
                name="advanced"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue={advanceSharingOptions[0].value}
                render={({ field }) => (
                  <RadioGroup sx={{ margin: 0 }} {...field} id="advanced">
                    <Collapse in={advancedOpen}>
                      <Grid container direction={'column'}>
                        {advanceSharingOptions.map((item) => (
                          <Grid key={item.value} item>
                            <FormControlLabel
                              value={item.value}
                              sx={{ marginLeft: 0 }}
                              control={
                                <Radio size="small" sx={{ height: '36px' }} />
                              }
                              label={t(item.name)}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Collapse>
                  </RadioGroup>
                )}
              />
            </Stack>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default InviteUsersForm;
