import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import SearchableAutoComplete from 'src/components/SearchableAutoComplete';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  useCreateUserMutation,
  useLazyGetUserGroupsQuery,
  useLazyGetUserOrUserGroupsQuery
} from 'src/services/api';
import { IAccountType } from '../types';
import { getAccountTypeDetails } from '../utils';
import { RootState, useSelector, useDispatch } from 'src/redux/store';
import { ACCOUNT_TYPE, ALERT, LOOKUP } from 'src/types/enum';
import Select from 'src/components/Select';
import { DataApiInputParams } from 'src/types/api';
import useMutation from 'src/hooks/useMutation';
import { ErrorContext } from 'src/utils/errorMappings';
import { IS_NON_AD_ENABLED } from 'src/config';
import { isEmailValid } from 'src/utils/utils';

type FormValues = {
  users: StringKeys[];
  accountType: StringKeys;
  userGroups?: StringKeys[];
};

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

const InviteUsersPopup = ({ isOpen, onClose, setShowLoader }: IProps) => {
  const { t, i18n } = useTranslation();
  const { lookups } = useSelector((state: RootState) => state.data);
  const {
    user: { accountType, isNonTrialUser }
  } = useSelector((state: RootState) => state.auth);
  const { user: loggedInUser } = useSelector((state) => state.auth);

  const [createUser, { isLoading: isCreatingUser }] = useMutation<
    DataApiInputParams,
    StringKeys
  >({ api: useCreateUserMutation, errorContext: ErrorContext.USERS });

  const formMethods = useForm<FormValues>({
    defaultValues: {
      accountType: isNonTrialUser
        ? lookups[LOOKUP.ACCOUNT_TYPE]?.find(
            (item) => item.code === ACCOUNT_TYPE.REGULAR_USER
          )?.id
        : lookups[LOOKUP.ACCOUNT_TYPE]?.find(
            (item) => item.code === ACCOUNT_TYPE.TRIAL_USER
          )?.id
    }
  });
  const {
    handleSubmit,
    setError,
    control,
    watch,
    clearErrors,
    formState: { errors }
  } = formMethods;
  const watchUsers = watch('users', []);

  const handleClose = (e) => {
    onClose();
  };

  const onSubmit = handleSubmit(async (data: any) => {
    const emailList = data.users.map((item) => {
      if (typeof item === 'string') {
        return item;
      }
      return item?.email;
    });
    setShowLoader(true);
    await createUser({
      params: {
        params: {
          email_list: emailList.toString(),
          account_type_id: data?.accountType,
          ...(data?.userGroups?.length && {
            group_list: data?.userGroups?.map((item) => item.id).toString()
          })
        }
      },
      fallbackMsg: T.userInviteError,
      successMsg: T.userInviteSuccess
    });
    setShowLoader(false);
    onClose();
  });

  const validateEmail = useCallback(
    (value) => {
      if (typeof value === 'string') {
        const isValid = isEmailValid(value);
        if (isValid) {
          clearErrors('users');
          return true;
        } else {
          setError('users', {
            type: 'manual',
            message: t(T.invalidEmail)
          });
          return false;
        }
      } else if (errors.users) {
        clearErrors('users');
      }
      return true;
    },
    [errors]
  );

  const accountTypes = useMemo<IAccountType[]>(
    () => getAccountTypeDetails(lookups[LOOKUP.ACCOUNT_TYPE], t),
    [i18n.language, lookups]
  );

  const renderValue = useCallback(
    (value) => accountTypes?.find((_value) => _value.id === value)?.name,
    [accountTypes]
  );

  return (
    <Dialog
      open={isOpen}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.general.borderRadiusXl
        })
      }}
      disableEscapeKeyDown
      onClose={handleClose}
      keepMounted
    >
      <DialogTitle>
        <Typography variant={'h3'}>{t(T.inviteUsers)}</Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={onSubmit}>
          <Grid mb={2} container spacing={1} direction={'column'}>
            <Grid item xs={12}>
              <Controller
                name="users"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue={[]}
                render={({ field: { onChange, ...rest } }) => (
                  <SearchableAutoComplete
                    {...rest}
                    searchApi={useLazyGetUserOrUserGroupsQuery}
                    optionLabel={'email'}
                    label={t(T.emailAddress)}
                    searchApiParams={{
                      new_users_only: true
                    }}
                    customValidation={validateEmail}
                    freeSolo={IS_NON_AD_ENABLED}
                    onChange={(_, value) => {
                      onChange(value);
                    }}
                    placeholder={t(T.search)}
                    multiple={true}
                    required={true}
                    customLabel={true}
                    hideEndAdornment={true}
                    renderOption={(props, option) => (
                      <ListItem
                        key={`${option.code}-${option.value}`}
                        sx={(theme) => ({
                          padding: `${theme.spacing(1)} ${theme.spacing(2)}`
                        })}
                        {...props}
                      >
                        <ListItemText
                          primary={option.name}
                          secondary={option.email}
                        />
                      </ListItem>
                    )}
                    autoSelect
                  />
                )}
              />
              {!!errors.users && (
                <Typography color="error">{errors.users?.message}</Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="accountType"
                control={control}
                rules={{
                  required: true
                }}
                defaultValue={[]}
                render={({ field: { onChange, ...rest } }) => (
                  <Select
                    {...rest}
                    id="accountType"
                    placeholder={t(T.accountTypeSelect)}
                    width={'100%'}
                    disabled={
                      accountType !== ACCOUNT_TYPE.ORGANIZATION_ADMINISTRATOR
                    }
                    customLabel={true}
                    label={t(T.accountType)}
                    onChange={onChange}
                    dataSource={accountTypes}
                    renderValue={renderValue}
                  />
                )}
              />
            </Grid>
            {isNonTrialUser && (
              <Grid item xs={12}>
                <Controller
                  name="userGroups"
                  control={control}
                  defaultValue={[]}
                  render={({ field: { onChange, ...rest } }) => (
                    <SearchableAutoComplete
                      {...rest}
                      searchApi={useLazyGetUserGroupsQuery}
                      optionLabel={'name'}
                      label={t(T.userGroup)}
                      onChange={(_, value) => {
                        onChange(value);
                      }}
                      placeholder={t(T.search)}
                      multiple={true}
                      customLabel={true}
                      hideEndAdornment={true}
                      renderOption={(props, option) => {
                        return (
                          <ListItem
                            key={`${option.id}`}
                            sx={(theme) => ({
                              padding: `${theme.spacing(1)} ${theme.spacing(2)}`
                            })}
                            {...props}
                          >
                            <ListItemText primary={option.name} />
                          </ListItem>
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" size="small">
          {t(T.cancel)}
        </Button>
        <Button
          onClick={onSubmit}
          variant="contained"
          type="submit"
          disabled={!watchUsers?.length || isCreatingUser}
          size="small"
          autoFocus
          color="secondary"
        >
          {t(T.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUsersPopup;
