import { ArticleOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { IFile } from 'src/types/document';
import { getFileIcon } from 'src/utils/files/file';
import {
  CustomDeleteIcon,
  CustomFileDetailsBox,
  FileIconTextWrapper
} from '../utils/styles';
import { StringKeys } from 'src/types/base';

type FileListIconProps = {
  file: IFile;
  selectedDocs: StringKeys[];
  setSelectedDocs: (docs: StringKeys[]) => void;
};

const FileListIcon: React.FC<FileListIconProps> = ({
  file,
  setSelectedDocs,
  selectedDocs
}) => {
  const [showDeleteIcon, setShowDeleteIcon] = useState<boolean>(false);
  const getStartIcon = useMemo(() => {
    if (file?.original_format) {
      const Icon = getFileIcon(file.original_format);
      if (Icon) {
        return <Icon height={30} width={30} />;
      }
    }
    return <ArticleOutlined fontSize="small" />;
  }, [file?.original_format]);

  const handleRemoveFile = () => {
    let updatedDocs: StringKeys[] = [...selectedDocs];
    updatedDocs = updatedDocs.filter((doc) => {
      const isNameDifferent = doc?.name && doc?.name !== file?.name;
      const isFileNameDifferent =
        doc?.file?.name && doc?.file?.name !== file?.name;

      return isNameDifferent || isFileNameDifferent;
    });

    setSelectedDocs(updatedDocs);
  };

  return (
    <CustomFileDetailsBox
      onMouseEnter={() => setShowDeleteIcon(true)}
      onMouseLeave={() => setShowDeleteIcon(false)}
    >
      <FileIconTextWrapper>
        <Box sx={{ height: '24px', width: '24px' }}>{getStartIcon}</Box>
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            alignSelf: 'end'
          }}
        >
          {file.name || 'No File Name'}
        </Typography>
      </FileIconTextWrapper>
      {showDeleteIcon && (
        <CustomDeleteIcon
          sx={{
            border: 'none',
            color: 'black',
            float: 'right'
          }}
          fontSize="large"
          onClick={handleRemoveFile}
        />
      )}
    </CustomFileDetailsBox>
  );
};

export default FileListIcon;
