import { Box, List } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as AssistantIcon } from 'src/assets/assistantIcons/assistant-bubble.svg';
import FilePermissionModal from 'src/content/Assistant/Components/FilePermissionModal';
import { startChat } from 'src/content/Documents/utils/gridUtils';
import DocumentsContext from 'src/contexts/FileManagerContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { setIsFavouritesFetching } from 'src/redux/slices/data';
import { RootState, useSelector } from 'src/redux/store';
import { useLazyGetAssistantDetailQuery } from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { FAVOURITE_TYPE, RESOURCE_TYPE } from 'src/types/enum';
import { ErrorContext } from 'src/utils/errorMappings';
import { renderSidebarFavouriteItems } from '../Favourites';
import getFavouriteItems, {
  calculateListHeight,
  FavouriteItems
} from '../Favourites/items';
import SidebarMenuItem from '../Menu/item';
import { MenuWrapper, SubMenuWrapper } from '../styles';

const Assistants = () => {
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const location = useLocation();
  const {
    userAppPermissions,
    userFavourites,
    isFavouritesFetching,
    icons,
    configuredModels
  } = useSelector((state) => state.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isFileModalOpen, setIsFileModalOpen] = useState<boolean>(false);
  const [currentAssistantDetails, setCurrentAssistantDetails] =
    useState<StringKeys>();
  const { selectedChat } = useSelector((state: RootState) => state.chat);
  const { isUpdatingFavourite, updateFavourite } = useContext(DocumentsContext);
  const [
    fetchAssistantDetails,
    {
      isFetching: isFetchingAssistant,
      isLoading: isLoadingAssistants,
      data: assistantData
    }
  ] = useLazyQuery({
    api: useLazyGetAssistantDetailQuery,
    errorContext: ErrorContext.ASSISTANT
  });

  const menuItems = useMemo(
    () =>
      getFavouriteItems(
        userAppPermissions,
        userFavourites || [],
        FAVOURITE_TYPE.ASSISTANT,
        icons,
        configuredModels
      ),
    [userFavourites, icons]
  );
  const listHeight = useMemo(() => calculateListHeight(menuItems), [menuItems]);
  const handleStartChatClick = async (id: number, name: string) => {
    const routeState = location?.state?.documentContext;
    await startChat({
      documentContext: {
        id,
        name,
        assistant_id: id
      },
      dispatch,
      navigate,
      selectedChat,
      routeState,
      resourceType: RESOURCE_TYPE.ASSISTANT
    });
  };

  const handleFavouriteClick = useCallback(
    (id: number, isFavourite: boolean) => {
      if (isUpdatingFavourite || isFavouritesFetching) return;

      dispatch(setIsFavouritesFetching(true));
      updateFavourite(id, !isFavourite, FAVOURITE_TYPE.ASSISTANT);
    },
    [
      dispatch,
      isUpdatingFavourite,
      isFavouritesFetching,
      FAVOURITE_TYPE.ASSISTANT
    ]
  );

  const handleFilePermissionModal = useCallback((id) => {
    fetchAssistantDetails({
      params: {
        params: {
          assistant_id: id,
          fetch_all: false
        }
      }
    });
  }, []);

  const handleCloseFileModal = () => setIsFileModalOpen(false);

  useEffect(() => {
    if (assistantData && !isFetchingAssistant) {
      setCurrentAssistantDetails({
        ...assistantData.data?.[0],
        aiModel: configuredModels.filter(
          (model) =>
            model.id === parseInt(assistantData?.data?.[0]?.settings.model_id)
        )[0]
      });
      setIsFileModalOpen(true);
    }
  }, [assistantData, isFetchingAssistant]);

  return (
    <>
      <MenuWrapper>
        <SubMenuWrapper>
          <List component="div">
            <SidebarMenuItem
              key={0}
              active={true}
              name={t('Assistants')}
              link={'/assistants'}
              badgeTooltip={'Assistants'}
              icon={AssistantIcon}
            />
          </List>
        </SubMenuWrapper>
      </MenuWrapper>
      {menuItems?.length ? (
        <Box height={listHeight}>
          <Box
            position={'absolute'}
            height={listHeight}
            width={'100%'}
            sx={{
              overflowY: 'auto',
              paddingLeft: '10px'
            }}
          >
            {menuItems.map((section: FavouriteItems, index: number) => (
              <MenuWrapper key={index}>
                <List component="div">
                  {renderSidebarFavouriteItems({
                    item: section.item,
                    path: location.pathname,
                    handleStartChatClick: handleStartChatClick,
                    handleFavouriteClick: handleFavouriteClick,
                    t: t,
                    favouriteType: FAVOURITE_TYPE.ASSISTANT,
                    hasFilePermissions: section?.item?.hasFilePermissions,
                    handleFilePermissionModal: handleFilePermissionModal
                  })}
                </List>
              </MenuWrapper>
            ))}
          </Box>
        </Box>
      ) : null}
      {isFileModalOpen && (
        <FilePermissionModal
          isFileModalOpen={isFileModalOpen}
          handleCloseFileModal={handleCloseFileModal}
          rowData={currentAssistantDetails}
        />
      )}
    </>
  );
};

export default Assistants;
