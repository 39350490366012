import { Avatar, Box } from '@mui/material';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import { ReactComponent as EInitials } from 'src/assets/egpt-logo.svg';
import { AzureWebPubSubChatContext } from 'src/contexts/AzureWebPubSubChatContext';
import { IconResource } from 'src/redux/slices/data';
import { RootState, useSelector } from 'src/redux/store';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { AnimatedText, SecondaryMessage } from '../styles';

interface ChatResponseProps {
  icons?: IconResource[];
  currentAssistantDetails?: StringKeys;
}

const assistantIconStyle = {
  width: '50px',
  height: '50px',
  borderRadius: '50px'
};

const ChatResponse = ({
  icons,
  currentAssistantDetails
}: ChatResponseProps) => {
  const { t } = useTranslation();
  const [typingText, setTypingText] = useState(`...`);
  const { chatStep } = useContext(AzureWebPubSubChatContext);
  const { assistantContext, assistantObj } = useSelector(
    (state: RootState) => state.assistant
  );

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setTypingText((prevText) => (prevText === '...' ? '' : '...'));
    }, 1000);

    return () => clearInterval(typingInterval);
  }, []);

  const isAssistant = useMemo(() => {
    return (
      assistantContext?.isAssistant ||
      assistantContext?.isPreview ||
      currentAssistantDetails?.settings?.icon_id
    );
  }, [
    assistantContext?.isAssistant,
    assistantContext?.isPreview,
    currentAssistantDetails?.settings?.icon_id
  ]);

  const isAssistantHasIcon = useMemo(() => {
    return (
      assistantObj?.assistantIcon || currentAssistantDetails?.settings?.icon_id
    );
  }, [assistantObj?.assistantIcon, currentAssistantDetails?.settings?.icon_id]);

  const assistantIcon = useMemo(() => {
    return assistantObj?.assistantIcon
      ? assistantObj?.assistantIcon
      : icons?.filter(
          (icon) =>
            icon.id === parseInt(currentAssistantDetails?.settings?.icon_id)
        )?.[0]?.icon;
  }, [assistantObj?.assistantIcon, currentAssistantDetails?.settings?.icon_id]);

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent={'flex-start'}
      py={1}
      width={'100%'}
    >
      <Avatar
        variant="rounded"
        sx={{
          width: 50,
          height: 50,
          borderRadius: 50,
          backgroundColor: (theme) => 'white'
        }}
        alt={t(T.empowerGPT)}
        style={{ objectFit: 'contain' }}
      >
        {isAssistant ? (
          isAssistantHasIcon ? (
            <img
              src={assistantIcon}
              alt="Assistant-Icon"
              style={assistantIconStyle}
            />
          ) : (
            <DefaultIcon
              style={{
                ...assistantIconStyle,
                padding: '10px',
                backgroundColor: 'rgba(222, 224, 229, 0.70)'
              }}
            />
          )
        ) : (
          <EInitials />
        )}
      </Avatar>

      <Box
        display="flex"
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        flexDirection="column"
        mr={2}
        ml={2}
        flex={'1'}
      >
        <SecondaryMessage>
          <AnimatedText>
            {chatStep ? t(chatStep) : t(T.understandingInput)}
            {typingText}
          </AnimatedText>
        </SecondaryMessage>
      </Box>
    </Box>
  );
};

export default ChatResponse;
