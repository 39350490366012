import { ArticleOutlined, InfoOutlined } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { useLazyGetDatasetQuery } from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { getFileIcon } from 'src/utils/utils';

interface DatasetDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  dataset: any;
}

const DatasetDetailsModal: FC<DatasetDetailsModalProps> = ({
  isOpen,
  onClose,
  dataset
}) => {
  const { t } = useTranslation();

  const [fetchDataset, { data, isLoading }] = useLazyQuery({
    api: useLazyGetDatasetQuery
  });

  useEffect(() => {
    fetchDataset({
      params: {
        params: {
          id: dataset?.id
        }
      }
    });
  }, [dataset?.id]);

  const getStartIcon = useCallback((originalFormat) => {
    if (originalFormat) {
      const Icon = getFileIcon(originalFormat);
      if (Icon) {
        return <Icon height={30} width={30} />;
      }
    }
    return <ArticleOutlined fontSize="small" />;
  }, []);

  const files = data?.data || [];

  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      dialogTitle={`${dataset?.name} - ${t(T.dataset)}`}
      showConfirmButton={false}
      sxHeader={{
        fontSize: '22px',
        fontWeight: 400,
        lineHeight: '36px',
        letterSpacing: '0.7px'
      }}
      sxDialog={{
        minWidth: '650px',
        '& .MuiDialogActions-root': {
          padding: '0 8px'
        }
      }}
      showCloseHeaderButton={false}
      cancelButtonText={t(T.close)}
    >
      {isLoading ? (
        <Loader />
      ) : files.length === 0 ? (
        <Box display="flex" gap="4px" marginY="8px">
          <InfoOutlined fontSize="small" color="error" />

          <Typography color="error">
            {t(T.allFilesDeletedInDatasetDisclaimer)}
          </Typography>
        </Box>
      ) : (
        <>
          <Box>
            <Typography
              variant="body1"
              sx={{
                backgroundColor: '#CAD9F266',
                padding: '8px'
              }}
            >
              {t(T.fileName)}
            </Typography>
            <Divider />
            <Box maxHeight="300px" sx={{ overflowY: 'auto' }}>
              {files.map((item: StringKeys, index: number) => (
                <Box key={index}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="8px"
                    padding="8px"
                  >
                    {getStartIcon(item?.original_format)}
                    <Typography variant="body1">{item?.name}</Typography>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </Box>
          </Box>

          {dataset?.is_corrupted && (
            <Box display="flex" gap="4px" marginTop="24px">
              <InfoOutlined fontSize="small" color="error" />

              <Typography color="error">
                {t(T.filesDeletedInDatasetDisclaimer)}
              </Typography>
            </Box>
          )}
        </>
      )}
    </DialogLayout>
  );
};

export default DatasetDetailsModal;
