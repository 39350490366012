import { useLazyGetUserOrUserGroupsQuery } from 'src/services/api';
import SearchableAutoComplete from '../SearchableAutoComplete';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { Box, Chip, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { GroupsOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import { CONTENT_SHARED_WITH_TYPE } from 'src/types/enum';
import { useCallback, useState } from 'react';

interface ShareSearchableAutoCompleteProps {
  label: string;
  freeSolo?: boolean;
  customValidation?: any;
  value?: any;
  onChange: any;
}

const ShareSearchableAutoComplete = ({
  label,
  freeSolo = false,
  customValidation,
  ...rest
}: ShareSearchableAutoCompleteProps) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  return (
    <SearchableAutoComplete
      {...rest}
      optionLabel="name"
      label={t(label)}
      searchApi={useLazyGetUserOrUserGroupsQuery}
      placeholder={t(T.search)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      searchApiParams={{
        search_group: true
      }}
      customValidation={customValidation}
      freeSolo={freeSolo}
      renderOption={(props, option) => (
        <ListItem
          key={`${option.code}-${option.value}`}
          sx={(theme) => ({
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`
          })}
          {...props}
        >
          <ListItemIcon sx={{ minWidth: 'unset', paddingRight: '16px' }}>
            {option.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP ? (
              <GroupsOutlined />
            ) : (
              <PersonOutlineOutlined />
            )}
          </ListItemIcon>
          <ListItemText
            primary={typeof option === 'string' ? option : option.name}
            secondary={option.email}
          />
        </ListItem>
      )}
      sx={{
        '& .MuiAutocomplete-tag': {
          maxWidth: '70%'
        },
        '& .MuiAutocomplete-input': {
          flexBasis: '90%'
        },
        '& .MuiAutocomplete-clearIndicator': {
          display: 'none'
        }
      }}
      renderTags={
        isFocused
          ? (value, getTagProps) => (
              <Box maxHeight={'70px'} overflow={'auto'} width={'100%'}>
                {value.map((option, index) => {
                  let value = option;
                  if (typeof option === 'object') value = option.name;
                  return (
                    <Chip
                      key={index}
                      variant="filled"
                      label={value}
                      {...getTagProps({ index })}
                    />
                  );
                })}
              </Box>
            )
          : undefined
      }
      multiple
      required
      customLabel
      autoSelect
      hideEndAdornment={true}
    />
  );
};

export default ShareSearchableAutoComplete;
