import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CustomAutoComplete from 'src/components/CustomAutoComplete';
import ItemWithDetails from 'src/components/ItemWithDetails';
import { updateChatModel } from 'src/redux/slices/chat';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

const ChatModels = ({ isDisabled }: { isDisabled: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { selectedChat: chatId, chatContexts } = useSelector(
    (state: RootState) => state.chat
  );
  const { configuredModels } = useSelector((state) => state.data);
  const { assistantObj } = useSelector((state) => state.assistant);

  const onChangeModel = (event, newValue) => {
    dispatch(
      updateChatModel({
        chatId: chatId,
        chatModel: newValue
      })
    );
  };

  const renderModelUi = useCallback((props, option) => {
    return (
      <li {...props}>
        <ItemWithDetails
          name={option?.name}
          displayName={option?.display_name}
          comment={option?.comment}
          showCheckbox={false}
          imageSize={'small'}
        />
      </li>
    );
  }, []);

  return (
    <CustomAutoComplete
      multiple={false}
      readOnly={true}
      label="ai-models"
      placeholder={t(T.selectModels)}
      dataSource={
        assistantObj?.aiModel ? [assistantObj?.aiModel] : configuredModels || []
      }
      onChange={onChangeModel}
      value={assistantObj?.aiModel || chatContexts[chatId].chatModel}
      optionLabel={'display_name'}
      renderOption={renderModelUi}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      sx={{
        width: '130px',
        '& .MuiAutocomplete-popupIndicator': {
          mt: '2px',
          color: '#26389A!important',
          ':hover': {
            background: 'none!important'
          }
        },
        '.MuiAutocomplete-popupIndicator.Mui-disabled': {
          color: 'rgba(0, 0, 0, 0.38)!important'
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          fontSize: '13px',
          fontWeight: '400',
          p: '0px!important',
          height: '24px !important',
          textFillColor: '#222F59'
        }
      }}
      disableSearch={isDisabled}
      disabled={isDisabled}
      disableClearable
      popperWidth={400}
      popperMaxHeight={360}
      optionStyles={true}
      disabledInputStroke={'rgba(0, 0, 0, 0.38)'}
    />
  );
};

export default ChatModels;
