import {
  Box,
  CircularProgress,
  Dialog,
  Button,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment
} from '@mui/material';
import { debounce, differenceBy } from 'lodash';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import Loader from 'src/components/Loader';
import {
  useCreateUserGroupMutation,
  useLazyGetUserGroupsQuery,
  useLazyGetUsersQuery,
  useUpdateUserGroupsMutation
} from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { User } from 'src/types/user';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import UserList from './UserList';
import TextField from 'src/components/TextField';
import { Controller, useForm } from 'react-hook-form';
import { Clear } from '@mui/icons-material';
import DialogTitle from 'src/components/Dialog/DialogTitle';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import { ErrorContext } from 'src/utils/errorMappings';
import useMutation from 'src/hooks/useMutation';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  userGroupId?: number | string;
};

const AddUserGroup: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [addedUsers, setAddedUsers] = useState<User[]>([]);
  const [fetchUsers, { data: fetchedUsers, isFetching: isLoadingUsersList }] =
    useLazyQuery<DataApiInputParams, User[]>({
      api: useLazyGetUsersQuery,
      errorContext: ErrorContext.USERS
    });
  const [
    fetchUserGroupDetail,
    { data: userGroupDetails, isFetching: isFetchingUserGroupDetails }
  ] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetUserGroupsQuery,
    errorContext: ErrorContext.USER_GROUPS
  });
  const [createGroup, { isLoading: isUserGroupCreating }] = useMutation({
    api: useCreateUserGroupMutation,
    errorContext: ErrorContext.USER_GROUPS
  });
  const [updateUserGroup] = useMutation({
    api: useUpdateUserGroupsMutation,
    errorContext: ErrorContext.USER_GROUPS
  });
  const { control, handleSubmit, watch, setValue } = useForm<{ name: string }>({
    defaultValues: {
      name: ''
    },
    reValidateMode: 'onBlur'
  });
  const userGroupName = watch('name');

  useEffect(() => {
    if (props.userGroupId) {
      fetchUserGroupDetail({
        params: {
          params: {
            show_details: true,
            group_id: props.userGroupId
          }
        }
      });
    }
  }, [props.userGroupId]);

  useEffect(() => {
    if (userGroupDetails && props.userGroupId) {
      setAddedUsers(userGroupDetails.members);
      setValue('name', userGroupDetails.name);
    }
  }, [userGroupDetails, props.userGroupId]);

  const handleToggleUser = useCallback(
    (user: User) => {
      if (addedUsers.find(({ id }) => id === user.id)) {
        setAddedUsers((prevState) =>
          prevState.filter(({ id }) => id !== user.id)
        );
      } else {
        setAddedUsers((prevState) => [...prevState, user]);
      }
    },
    [addedUsers]
  );

  const handleSearch = useCallback(
    debounce((s: string) => {
      fetchUsers({ params: { params: { s } } });
    }, 250),
    []
  );

  const handleUserSearchChange = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchQuery(() => e.target.value);
      handleSearch(e.target.value);
    },
    []
  );

  const handleCancelClick = useCallback(() => {
    setAddedUsers([]);
    clearSearchQuery();
    props.onClose();
  }, []);

  const handleSubmitUserGroup = useCallback(
    async ({ name }) => {
      if (props.userGroupId) {
        const newUsers = differenceBy(
          addedUsers,
          userGroupDetails.members,
          'id'
        );
        const removedUsers = differenceBy(
          userGroupDetails.members,
          addedUsers,
          'id'
        ) as any[];
        await updateUserGroup({
          params: {
            params: {
              id: props.userGroupId,
              add_userList: newUsers.map((item) => item.id),
              remove_userList: removedUsers.map((item) => item.id),
              name
            }
          },
          fallbackMsg: T.failedToUpdateUserGroup,
          successMsg: T.userGroupUpdatedSuccessfully
        });
      } else {
        await createGroup({
          params: {
            params: {
              name,
              description: '',
              userList: addedUsers.map((user) => user.id)
            }
          },
          fallbackMsg: T.failedToCreateUserGroup,
          successMsg: T.userGroupCreatedSuccessfully
        });
      }
      handleCancelClick();
    },
    [addedUsers]
  );

  const clearSearchQuery = useCallback(() => setSearchQuery(''), []);

  const getUserList = useCallback(() => {
    if (searchQuery.length) {
      if (!isLoadingUsersList && fetchedUsers) {
        return (
          <UserList
            userList={fetchedUsers}
            handleToggleUser={handleToggleUser}
            existingUsers={addedUsers}
          />
        );
      } else {
        return (
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            padding={3}
          >
            <CircularProgress />
          </Box>
        );
      }
    } else if (addedUsers.length) {
      return (
        <UserList
          userList={addedUsers}
          handleToggleUser={handleToggleUser}
          existingUsers={addedUsers}
        />
      );
    } else {
      return null;
    }
  }, [searchQuery, fetchedUsers, addedUsers, isLoadingUsersList]);

  const dialogContent = useMemo(() => {
    if (isFetchingUserGroupDetails) {
      return (
        <Box sx={{ padding: 5 }}>
          <Loader />
        </Box>
      );
    }
    return (
      <>
        <Controller
          name={'name'}
          control={control}
          rules={{
            required: {
              value: true,
              message: t(T.required, { name: t(T.userGroupName) })
            },
            maxLength: {
              value: 30,
              message: t(T.maxLength, { name: t(T.userGroupName), length: 30 })
            }
          }}
          render={({
            field: { value, onChange, ...rest },
            formState: { errors }
          }) => (
            <TextField
              autoFocus
              name="userGroupName"
              label={t(T.name)}
              customLabel={true}
              onChange={onChange}
              fullWidth
              sx={(theme) => ({
                mb: theme.spacing(2)
              })}
              value={value}
              error={!!errors.name}
              required
              helperText={errors.name?.message}
              {...rest}
            />
          )}
        />
        <Box
          sx={(theme) => ({
            border: `1px solid ${theme.colors.primaryAlt.main}`,
            padding: theme.spacing(1)
          })}
        >
          <TextField
            name="userSearch"
            label={t(T.users)}
            customLabel={true}
            onChange={handleUserSearchChange}
            fullWidth
            placeholder={t(T.search)}
            value={searchQuery}
            InputProps={{
              endAdornment: searchQuery.length ? (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearchQuery} sx={{ padding: 0.5 }}>
                    <Clear fontSize="small" />
                  </IconButton>
                </InputAdornment>
              ) : null
            }}
          />
        </Box>
        {getUserList()}
      </>
    );
  }, [isFetchingUserGroupDetails, userGroupName, searchQuery, getUserList]);

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: (theme) => ({
          borderRadius: theme.general.borderRadiusXl
        })
      }}
    >
      <DialogTitle
        title={props.userGroupId ? t(T.editUserGroup) : t(T.addUserGroup)}
        showCloseIcon
        onClose={props.onClose}
      />
      <DialogContent>{dialogContent}</DialogContent>
      <DialogActions>
        <Box mt={5} display={'flex'} justifyContent={'flex-end'}>
          <Button
            variant="outlined"
            color="primary"
            sx={{ mr: 2 }}
            onClick={handleCancelClick}
            disabled={isUserGroupCreating}
          >
            {t(T.cancel)}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={!userGroupName?.length || !addedUsers.length}
            onClick={handleSubmit(handleSubmitUserGroup)}
          >
            {t(T.confirm)}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserGroup;
