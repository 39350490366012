import { Box, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { updateInitiateAssistantState } from 'src/redux/slices/assistant';
import { useDispatch } from 'src/redux/store';
import { StringKeys } from 'src/types/base';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  MAX_CONVERSATION_STARTER_LENGTH,
  MAX_CONVERSATION_STARTERS
} from '../utils/utils';
import ConversationStarter from './ConversationStarter';

const ConversationStarters = ({
  conversationStarters,
  setConversationStarters,
  newAssistant,
  setStarterError
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [focusedStarter, setFocusedStarter] = useState<number>(0);

  const handleRemoveStarter = useCallback(
    (starterId: number) => {
      let tempStarterError = false;
      const updatedStarters = conversationStarters
        .filter((starter: StringKeys) => starter.id !== starterId)
        .map((starter: StringKeys) => {
          if (starter.name.length > MAX_CONVERSATION_STARTER_LENGTH) {
            tempStarterError = true;
          }
          return {
            ...starter,
            id: starter.id > starterId ? starter.id - 1 : starter.id
          };
        });
      const nextStarterIndex = starterId;
      if (
        nextStarterIndex < updatedStarters.length &&
        updatedStarters[nextStarterIndex]?.name.length === 0
      ) {
        updatedStarters.splice(nextStarterIndex, 1);
      }
      if (
        updatedStarters.length < MAX_CONVERSATION_STARTERS &&
        !updatedStarters.some((starter: StringKeys) => starter.name === '')
      ) {
        updatedStarters.push({ id: updatedStarters.length + 1, name: '' });
      }
      setStarterError(tempStarterError);
      setConversationStarters(updatedStarters);

      dispatch(
        updateInitiateAssistantState({
          ...newAssistant,
          conversationStarter: updatedStarters
        })
      );
    },
    [conversationStarters, dispatch, newAssistant]
  );

  const handleOnChange = useCallback(
    (id, starterValue) => {
      if (starterValue.length === 0) {
        handleRemoveStarter(id);
        return;
      }

      setFocusedStarter(id);

      let hasStarterError = false;
      let updatedStarters: StringKeys;

      if (conversationStarters.length > 0) {
        updatedStarters = conversationStarters.map((starter: StringKeys) => {
          if (starter.id === id) {
            starter = { ...starter, name: starterValue };
          }
          if (starter.name.length > MAX_CONVERSATION_STARTER_LENGTH) {
            hasStarterError = true;
          }
          return starter;
        });
      } else {
        updatedStarters = [
          { id: conversationStarters?.length + 1, name: starterValue }
        ];
      }
      if (
        updatedStarters.length < MAX_CONVERSATION_STARTERS &&
        starterValue.length > 0 &&
        !updatedStarters.some((starter: StringKeys) => starter.name === '')
      ) {
        updatedStarters.push({ id: updatedStarters.length + 1, name: '' });
      }

      setStarterError(hasStarterError);
      setConversationStarters(updatedStarters);

      dispatch(
        updateInitiateAssistantState({
          ...newAssistant,
          conversationStarter: updatedStarters
        })
      );
    },
    [newAssistant, conversationStarters, dispatch]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mb="10px">
        <Typography variant="body1">{t(T.conversationStarters)}</Typography>
        <Typography variant="body1">
          {t(T.conversationStartersCount, {
            totalCon: conversationStarters?.filter(
              (starter: StringKeys) => !!starter?.name?.length
            )?.length
          })}
        </Typography>
      </Box>
      <Box>
        {conversationStarters?.map((item, index) => {
          return (
            <Box key={index}>
              <ConversationStarter
                id={index + 1}
                name={item.name}
                handleStarterChange={handleOnChange}
                removeStarter={handleRemoveStarter}
                isFocused={focusedStarter === item.id}
                showAddButton={false}
                showDeleteButton={!!item?.name?.length}
              />
              {item.name?.length > MAX_CONVERSATION_STARTER_LENGTH && (
                <Typography
                  variant="body1"
                  color="red"
                  mt={'-10px'}
                  mb={'10px'}
                >
                  {t(
                    item.name.length === 0
                      ? T.conversationStartersErrorMessage
                      : T.conversationStarterErrorMessage
                  )}
                </Typography>
              )}
            </Box>
          );
        })}
        {conversationStarters.length < MAX_CONVERSATION_STARTERS &&
          !conversationStarters.some((starter) => starter.name === '') && (
            <ConversationStarter
              id={conversationStarters.length + 1}
              name={''}
              handleStarterChange={handleOnChange}
              isFocused={!focusedStarter && true}
              showDeleteButton={false}
            />
          )}
      </Box>
    </Box>
  );
};

export default ConversationStarters;
