import { InputBaseComponentProps, SliderProps } from '@mui/material';
import _ from 'lodash';
import { TFunction } from 'react-i18next';
import { TENANT_NAME } from 'src/config';
import { DataApiInputParams } from 'src/types/api';
import { AiModel, StringKeys } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import { CHAT_CLASS, CHAT_SOURCE_TYPES, LOOKUP } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { IResponseFeedback } from './types';
export const MESSAGE_PAGE_SIZE = 100;
export const CHAT_LIST_PAGE_SIZE = 30;

export const Fields = {
  Temperature: 'Temperature',
  Tokens: 'Max length (tokens)',
  Sequences: 'Stop sequences',
  Probabilities: 'Top probabilities',
  Frequency: 'Frequency penalty',
  Presence: 'Presence penalty'
};

export const InputConfig: InputBaseComponentProps = {
  inputMode: 'numeric'
  // pattern: '[0-9]*'
};

export const SliderConfig: SliderProps = {
  marks: true,
  valueLabelDisplay: 'on'
};

export const isDifferentDay = (prevDate: string, currDate: string) => {
  return (
    currDate.toString().split('T')[0] !== prevDate?.toString().split('T')[0]
  );
};

export const getMessageTemplate = (
  textInput: string,
  chatId: number,
  model: AiModel
) => [
  {
    chat_id: chatId,
    id: null,
    message: textInput,
    parent_id: null,
    ts: new Date().toISOString(),
    type: 'q',
    model_id: model?.id,
    model_name: model?.name,
    model_display_name: model?.display_name
  }
];

export const getParamtersFomattedData = (
  data: any[]
): { [key: string]: string | number } => {
  let updatedData = {};
  data.forEach((item) => {
    updatedData[item.name] =
      item.data_type === 'text' ? item.value : parseFloat(item.value);
  });
  return updatedData;
};

const getFormattedPatchParameters = (
  data: { [z: string]: number | string }[],
  updated: { [key: string]: number | string }
): string => {
  let updatedData: { id: number | string; v: string }[] = data.map((item) => {
    return { id: item.id, v: `${updated[item.name]}` };
  });
  return JSON.stringify(updatedData);
};

export const chatListApiParams = (pageNumber: number) => {
  return { params: { p: pageNumber, ps: CHAT_LIST_PAGE_SIZE } };
};

export const getChatMessagesApiParams = (
  chatId: number,
  pageNumber: number
) => {
  return {
    params: { p: pageNumber, ps: MESSAGE_PAGE_SIZE, chat_id: chatId }
  };
};

export const getChatParametersApiParams = (chatId: number) => {
  return {
    params: { chat_id: chatId }
  };
};

export const getUpdateParametersApiParams = (
  data,
  updatedData
): DataApiInputParams => {
  return {
    params: { params: getFormattedPatchParameters(data, updatedData) }
  };
};

export const getReactionApiParams = (
  id,
  value,
  details?: IResponseFeedback
): DataApiInputParams => {
  let rDetails: any = {};
  if (details?.feedbackId?.length) {
    rDetails.feedback_detail_ids = details.feedbackId.join(',');
  }
  if (details?.feedbackComment) {
    rDetails.feedback_comment = details.feedbackComment;
  }

  return {
    params: {
      id: id,
      feedback_id: value,
      ...rDetails
    }
  };
};

export const getReactionsKeyValue = (lookups) => {
  let reactions: { [code: string]: number } = {};
  lookups[LOOKUP.REACTION]?.forEach((item) => {
    reactions[item.code] = item.id;
  });
  return reactions;
};

export const getHistoryStatus = (chats, chatId) => {
  if (chatId && chats.length) {
    return chats.find((item) => item.id === chatId)?.history_context;
  }
  return true;
};

export const addButtonsAfterDocument = (text: string) => {
  if (text) {
    text = text.replace(
      /\[(Document:\s*\d+(?:,\s*\d+)*|(?:Document:\s*\d+\s*,?\s*)+)\]/gi,
      (match) => {
        const docsArray = match.match(/\d+/g) || [];
        const newDocs = docsArray.map(
          (num: string) => `[Document: ${num}](#button)`
        );
        return newDocs.join(' ');
      }
    );
    return text;
  }
};

export const sortFiles = (formattedMessage) => {
  const pattern = /\[Document:\s*(.*?)\]/gi;
  const response = formattedMessage?.response || '';
  const matches = response.match(pattern);
  if (!matches) return formattedMessage?.files;
  const orderMap = new Map();
  let order = 0;
  for (const match of matches) {
    const documentIds = match.match(/\d+/g).map(Number);
    for (const documentId of documentIds) {
      if (!orderMap.has(documentId)) {
        orderMap.set(documentId, order++);
      }
    }
  }
  return formattedMessage?.files.sort((a, b) => {
    const orderA = orderMap.get(Number(a.document_id));
    const orderB = orderMap.get(Number(b.document_id));
    return orderA - orderB;
  });
};

export const removeAIMessage = (text: string) => {
  const regex = /\[AI.+?:\s*(\d+.*?)\]/gi;
  return text.replace(regex, '');
};

export const getShareChatApiParams = (chatId: number) => {
  return {
    params: { r_id: chatId }
  };
};

export const getShareChatMessagesApiParams = (id: string) => {
  return {
    params: { share_id: id }
  };
};

export const splitLastWordFromString = (
  str: string
): { remainingString: string; lastWord: string } => {
  let i;
  for (i = str.length - 1; i >= 0; i--) {
    if ([' ', '\n'].includes(str[i])) {
      break;
    }
  }

  return {
    remainingString: str.substring(0, i + 1),
    lastWord: str.substring(i + 1)
  };
};

export enum KEYBOARD_EVENT {
  DOWN = 'ArrowDown',
  UP = 'ArrowUp',
  ENTER = 'Enter'
}

export const chatErrors = [
  'CONTENT_FILTER',
  'GENERAL_ERROR',
  'UNSUPPORTED_LANGUAGE',
  '429'
];
export const groupChatsByDate = (chats: ChatData[]) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - today.getDay());

  const groupedChats = _.groupBy(chats, (chat) => {
    const chatDate = new Date(chat.ts);
    chatDate.setHours(0, 0, 0, 0);

    if (chatDate.getTime() === today.getTime()) {
      return 'today';
    } else if (chatDate >= startOfWeek) {
      return 'thisWeek';
    } else {
      return 'thisMonthAndOlder';
    }
  });

  return {
    today: groupedChats.today || [],
    thisWeek: groupedChats.thisWeek || [],
    thisMonthAndOlder: groupedChats.thisMonthAndOlder || []
  };
};

export const preprocessMathExpressions = (content: string) => {
  return content
    ?.replace(/\\\[(\s*[\s\S]*?)\\\]/g, '$$$1$$')
    ?.replace(/\\\((.*?)\\\)/g, '$$$1$$');
};

export const getChatTypeText = (
  chatClass: string,
  chatType: string,
  currentAssistantDetails: StringKeys,
  t: TFunction<'translation', undefined>,
  assistantId?: number
) => {
  const isAssistantDeleted = assistantId === null || assistantId === 0;
  const isAssistantChat = chatClass === CHAT_CLASS.ASSISTANT;

  if (isAssistantChat) {
    return isAssistantDeleted
      ? t(T.assistantDeleted)
      : currentAssistantDetails?.name;
  }

  if (chatType === CHAT_SOURCE_TYPES.ChatGPT) {
    return t(T.worldKnowledge);
  }

  return t(T.tenantKnowledgeBase, {
    tenant: TENANT_NAME || t(T.enterprise)
  });
};