import { Box, CircularProgress, List } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { ReactComponent as BackIcon } from 'src/assets/ChevronLeft.svg';
import {
  CHAT_LIST_PAGE_SIZE,
  chatListApiParams,
  groupChatsByDate
} from 'src/content/Chat/utils';
import useLazyQuery from 'src/hooks/useLazyQuery';
import {
  setChats,
  toggleChatHistoryPan,
  updateChatsPageNumber
} from 'src/redux/slices/chat';
import { RootState, useDispatch } from 'src/redux/store';
import { useLazyGetChatsQuery } from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { StringKeys } from 'src/types/base';
import { ChatData } from 'src/types/chat';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { SubMenuWrapper } from '../styles';
import GroupChat from './GroupChat';
import { ChatBox, HeaderBox, HistoryText } from './styles';

const HistoryPanel = () => {
  const { t }: { t: TFunction<'translation', undefined> } = useTranslation();
  const dispatch = useDispatch();
  const [chatList, setChatList] = useState([]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const [currentPageLength, setCurrentPageLength] = useState<number>(0);
  const [fetchChat, { data, isLoading }] = useLazyQuery<
    DataApiInputParams,
    StringKeys
  >({
    api: useLazyGetChatsQuery,
    errorContext: ErrorContext.CHAT
  });
  const navigate = useNavigate();
  const { chats, selectedChat } = useSelector((state: RootState) => state.chat);

  const fetchChatList = () => {
    fetchChat({ params: chatListApiParams(page) });
  };

  const onTitleEdited = (editedId: number, updatedTitle: string) => {
    setChatList((updatedData) =>
      updatedData.map((item: ChatData) =>
        item.id === editedId ? { ...item, subject: updatedTitle } : item
      )
    );
  };

  const onChatDeleted = (deletedId: number) => {
    let updatedData = [...chatList];
    _.remove(updatedData, (item: ChatData) => item.id === deletedId);
    setChatList(updatedData);
    if (deletedId === selectedChat)
      navigate('/chat', {
        replace: true
      });
    setPage(0);
  };

  const handleScroll = (_: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!scrollRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (
      Math.floor(scrollTop) === Math.floor(scrollHeight - clientHeight) &&
      currentPageLength >= CHAT_LIST_PAGE_SIZE * (page + 1)
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const groupedChats = useMemo(() => groupChatsByDate(chatList), [chatList]);

  const handleOpenHistoryDrawer = useCallback(() => {
    dispatch(toggleChatHistoryPan());
  }, []);

  useEffect(() => {
    if (data?.data) {
      if (page === 0) {
        dispatch(setChats(data.data));
      } else {
        dispatch(setChats([...chats, ...data.data]));
      }
    }
  }, [data]);

  useEffect(() => {
    if (chats.length) {
      setChatList(chats);
      setCurrentPageLength(chats.length || 0);
    }
  }, [chats]);

  useEffect(() => {
    dispatch(updateChatsPageNumber(page));
    fetchChatList();
  }, [page]);

  return (
    <>
      <HeaderBox onClick={handleOpenHistoryDrawer}>
        <BackIcon fill="white" height={'16px'} width={'16px'} />
        <HistoryText>{t(T.chatHistory)}</HistoryText>
      </HeaderBox>
      {isLoading ? (
        <Box
          display={'flex'}
          justifyContent={'center'}
          width={'100%'}
          height={'100%'}
        >
          <CircularProgress
            color="secondary"
            sx={{
              alignSelf: 'center'
            }}
          />
        </Box>
      ) : (
        <Box position={'relative'} flex={1} marginBottom={'10px'}>
          <ChatBox onScroll={handleScroll} ref={scrollRef}>
            <SubMenuWrapper
              sx={{
                position: 'relative'
              }}
            >
              <List component="div" disablePadding>
                <GroupChat
                  groupTitle={t(T.today)}
                  chatGroup={groupedChats.today}
                  onChatDeleted={onChatDeleted}
                  onTitleEdited={onTitleEdited}
                />
                <GroupChat
                  groupTitle={t(T.thisWeek)}
                  chatGroup={groupedChats.thisWeek}
                  onChatDeleted={onChatDeleted}
                  onTitleEdited={onTitleEdited}
                />
                <GroupChat
                  groupTitle={t(T.thisMonth)}
                  chatGroup={groupedChats.thisMonthAndOlder}
                  onChatDeleted={onChatDeleted}
                  onTitleEdited={onTitleEdited}
                />
              </List>
            </SubMenuWrapper>
          </ChatBox>
        </Box>
      )}
    </>
  );
};

export default HistoryPanel;
