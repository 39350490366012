import { CheckCircleOutline, ChevronLeft } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Divider,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Slide,
  Tooltip,
  Typography
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FullScreen } from 'src/assets/assistantIcons/full-screen.svg';
import { ReactComponent as CloudUploadIcon } from 'src/assets/assistantIcons/upload-icon.svg';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import TextField from 'src/components/TextField';
import ChatMessageList from 'src/content/Chat/Conversation';
import { resetAssistantContext } from 'src/content/Documents/utils/gridUtils';
import { AzureWebPubSubChatProvider } from 'src/contexts/AzureWebPubSubChatContext';
import { DocumentsContextProvider } from 'src/contexts/FileManagerContext';
import useMutation from 'src/hooks/useMutation';
import {
  AssistantType,
  initialAssistantState,
  updateAssistantContext,
  updateInitiateAssistantState,
  updateStarterQuestion
} from 'src/redux/slices/assistant';
import {
  clearChatSnapshot,
  createChatSnapshot,
  defaultChatContext,
  setSelectedChat
} from 'src/redux/slices/chat';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  useCreateAssistantMutation,
  useUpdateAssistantMutation,
  useUploadIconMutation
} from 'src/services/api';
import { StringKeys } from 'src/types/base';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import ImportFiles from '../Components/importFiles';
import {
  CreateAssistantFormContainer,
  IconImage,
  IconsContainer,
  VisuallyHiddenInput
} from '../utils/styles';
import {
  compareAssistantObject,
  defaultAssistantProps,
  MAX_DESC_LENGTH,
  MAX_ICON_FILE_SIZE,
  MAX_INSTRUCTIONS_LENGTH,
  MAX_NAME_LENGTH
} from '../utils/utils';

import { ReactComponent as AddIcon } from 'src/assets/assistantIcons/add-icon.svg';
import AiModels from '../Components/AiModels';
import Capabilities from '../Components/Capabilities';
import ConversationStarters from '../Components/ConversationStarters';
import '../utils/iconStyles.css';
import { FormValues, IConversationStarter } from '../utils/types';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateAssistantForm = ({
  title,
  isOpen,
  onClose,
  handleFetchAssistants,
  assistantDetails,
  icons,
  setSharedDocument,
  setIsShareResourceDialogOpen,
  selectedFilter
}) => {
  const { t } = useTranslation();
  const { configuredModels, chatDefaultModel } = useSelector(
    (state) => state.data
  );
  const { assistantContext, assistantObj } = useSelector(
    (state) => state.assistant
  );
  const dispatch = useDispatch();
  const [isAssistantUpdate, setAssistantUpdate] = useState<boolean>(false);
  const [newAssistant, setNewAssistant] = useState<AssistantType>(
    initialAssistantState
  );
  const [tempObj, setTempObj] = useState<AssistantType>({});
  const [selectedDocs, setSelectedDocs] = useState<StringKeys[]>([]);
  const [isStarterError, setStarterError] = useState<boolean>(false);
  const [selectedIcon, setSelectedIcon] = useState<StringKeys>();
  const [showFilesGrid, setShowFilesGrid] = useState<boolean>(false);

  const [isIconModalPopupOpen, setIconModalPopupOpen] =
    useState<boolean>(false);

  const [isConfirmationModalOpen, setConfirmationModalOpen] =
    useState<boolean>(false);

  const [iconSizeError, setIconSizeError] = useState<boolean>(false);

  const [instructionModalPopupOpen, setInstructionModalPopupOpen] =
    useState<boolean>(false);
  const [conversationStarters, setConversationStarters] = useState<
    IConversationStarter[]
  >([]);

  const [isFileSelectionModalOpen, setFileSelectionModalOpen] =
    useState<boolean>(false);

  const [initiateAssistant] = useMutation({
    api: useCreateAssistantMutation,
    errorContext: ErrorContext.ASSISTANT
  });

  const [uploadIcon] = useMutation({
    api: useUploadIconMutation,
    errorContext: ErrorContext.ICONS
  });

  const [updateAssistant] = useMutation({
    api: useUpdateAssistantMutation,
    errorContext: ErrorContext.ASSISTANT
  });

  const formMethods = useForm<FormValues>({
    defaultValues: {},
    mode: 'onChange'
  });

  const { control, watch, handleSubmit } = formMethods;

  useEffect(() => {
    if (assistantDetails) {
      setAssistantUpdate(true);
      initializeFormData();
      let tempObj: AssistantType = {
        ...assistantDetails,
        name: assistantDetails?.name || '',
        description: assistantDetails?.settings?.description || '',
        instructions: assistantDetails?.settings?.instructions || '',
        conversationStarter: assistantDetails?.settings?.starters || [],
        assistantIcon:
          icons?.filter(
            (icon) => icon.id == assistantDetails?.settings?.icon_id
          )?.[0]?.icon || '',
        aiModel: configuredModels.filter(
          (model) => model.id === parseInt(assistantDetails?.settings?.model_id)
        )?.[0],
        fileList:
          assistantDetails?.permission?.resources?.map((r) => ({
            rid: r.rid,
            type_id: r?.file?.type_id
          })) || [],
        isWebSearch: assistantDetails?.settings?.web_search_enabled || false,
        worldKnowledge: assistantDetails?.settings?.world_knowledge || false,
        permission: assistantDetails?.permission || {},
        id: assistantDetails?.id || null,
        settings: assistantDetails?.settings || {}
      };
      setTempObj(tempObj);
      dispatch(updateInitiateAssistantState(tempObj));
      setSelectedIcon(
        icons?.filter(
          (icon) => icon.id == assistantDetails?.settings?.icon_id
        )?.[0]
      );
    } else {
      setNewAssistant({
        ...newAssistant,
        aiModel: chatDefaultModel,
        worldKnowledge: true
      });
      setTempObj({
        ...newAssistant,
        aiModel: chatDefaultModel,
        worldKnowledge: true
      });
      setTempObj({
        ...newAssistant,
        aiModel: chatDefaultModel,
        worldKnowledge: true
      });
      dispatch(
        updateInitiateAssistantState({
          ...assistantObj,
          aiModel: chatDefaultModel,
          worldKnowledge: true
        })
      );
    }

    // Set Chat context for Preview
    dispatch(
      createChatSnapshot({
        chatId: 0,
        chatContext: {
          ...defaultChatContext,
          documentContext: {
            id: assistantDetails?.id || null,
            name: assistantDetails?.name || ''
          }
        }
      })
    );

    dispatch(setSelectedChat(0));
    dispatch(
      updateAssistantContext({
        ...assistantContext,
        isAssistant: true,
        isStartChat: false,
        isPreview: true
      })
    );

    setTimeout(() => {
      dispatch(setSelectedChat(0));
    }, 100);
  }, [assistantDetails]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name) {
        let temp = { ...tempObj, [name]: value[name] };
        setTempObj(temp);
        dispatch(updateInitiateAssistantState(temp));
      }
    });
  }, [watch]);

  const isSubmitting = useMemo(() => {
    const isNameLength =
      assistantObj?.name.length > 0 &&
      assistantObj?.name.length <= MAX_NAME_LENGTH;
    const isDescriptionLength =
      assistantObj?.description.length === 0
        ? true
        : assistantObj?.description.length > 0 &&
          assistantObj?.description.length <= MAX_DESC_LENGTH;
    const isInstructionsLength =
      assistantObj?.instructions.length === 0
        ? true
        : assistantObj?.instructions.length > 0 &&
          assistantObj?.instructions.length <= MAX_INSTRUCTIONS_LENGTH;

    const isEnterpriseKnowledge: boolean =
      assistantObj?.worldKnowledge || assistantObj?.isWebSearch
        ? true
        : assistantObj?.fileList?.length > 0 &&
          !assistantObj?.isWebSearch &&
          !assistantObj?.worldKnowledge;

    const isModelSelected: boolean = !!assistantObj?.aiModel;

    if (isStarterError) {
      formMethods.setError('conversationStarter', {
        message: t(T.conversationStartersErrorMessage)
      });
    } else {
      formMethods.setError('conversationStarter', null);
    }
    return (
      isNameLength &&
      isDescriptionLength &&
      isInstructionsLength &&
      !isStarterError &&
      isEnterpriseKnowledge &&
      isModelSelected
    );
  }, [assistantObj]);

  const initializeFormData = () => {
    formMethods.setValue('name', assistantDetails?.name || null);
    formMethods.setValue(
      'description',
      assistantDetails?.settings?.description || null
    );
    formMethods.setValue(
      'instructions',
      assistantDetails?.settings?.instructions || null
    );
    formMethods.setValue(
      'assistantIcon',
      assistantDetails?.settings?.icon_id || null
    );

    // Set Starters
    if (assistantDetails?.settings?.starters?.length > 0) {
      setConversationStarters(
        assistantDetails?.settings?.starters?.map((starter, index) => ({
          id: index + 1,
          name: starter
        }))
      );
    }
    if (
      assistantDetails?.permission?.resources?.length > 0 &&
      assistantDetails?.permission?.resources[0]?.rid
    ) {
      setShowFilesGrid(true);
      setSelectedDocs(assistantDetails.permission.resources);
    }
    if (assistantDetails) {
      setNewAssistant({
        ...newAssistant,
        aiModel: configuredModels.filter(
          (aiModal) => aiModal.id == assistantDetails.settings.model_id
        )[0],
        isWebSearch: assistantDetails?.settings?.web_search_enabled,
        worldKnowledge: assistantDetails?.settings?.world_knowledge
      });
    }
  };

  const onSubmit = (data: FormValues) => {
    let assistantParams = {
      name: data.name,
      description: data?.description || '',
      instructions: data?.instructions || '',
      starters: JSON.stringify(conversationStarters),
      ...(selectedIcon &&
        selectedIcon?.id && {
          icon_id: selectedIcon.id
        }),
      model_id: newAssistant?.aiModel?.id || 0,
      ...(assistantDetails &&
        assistantDetails?.id && {
          assistant_id: assistantDetails.id
        }),
      rid_list: JSON.stringify([]),
      web_search_enabled: !!newAssistant.isWebSearch,
      world_knowledge: !!newAssistant.worldKnowledge
    };

    if (!newAssistant.isWebSearch && !newAssistant?.worldKnowledge) {
      if (selectedDocs.length > 0) {
        assistantParams['rid_list'] = JSON.stringify(
          selectedDocs.map((doc) => ({
            rid: doc?.id || doc?.rid,
            type_id: doc?.file?.type_id || doc?.type_id
          }))
        );
      } else {
        if (assistantObj?.worldKnowledge) {
          assistantParams['world_knowledge'] = assistantObj?.worldKnowledge;
        } else if (assistantObj?.isWebSearch) {
          assistantParams['web_search_enabled'] = assistantObj?.isWebSearch;
        }
      }
    }

    if (isAssistantUpdate) {
      // Update Assistant
      updateAssistant({
        params: {
          params: assistantParams
        },
        successMsg: t(T.assistantUpdatedSuccessfully),
        successCallback(data) {
          onClose();
          handleFetchAssistants(true, selectedFilter);
          resetAssistantContext(dispatch);
          setSelectedDocs([]);
          setConversationStarters([]);
          setNewAssistant(defaultAssistantProps);
          formMethods.reset();
          dispatch(clearChatSnapshot(0));
          dispatch(setSelectedChat(undefined));
          dispatch(updateInitiateAssistantState(initialAssistantState));
          dispatch(
            updateAssistantContext({
              isAssistant: true,
              isCreatingAssistant: false,
              isStartChat: false,
              isPreview: false,
              isUpdated: false
            })
          );
        }
      });
    } else {
      // Create Assistant Case
      initiateAssistant({
        params: {
          params: assistantParams
        },
        successMsg: t(T.assistantCreatedSuccessfully),
        successCallback(data) {
          onClose();
          setSharedDocument(data?.data[0]);
          setIsShareResourceDialogOpen(true);

          handleFetchAssistants(true, selectedFilter);
          dispatch(updateInitiateAssistantState(initialAssistantState));
          dispatch(
            updateAssistantContext({
              ...assistantContext,
              isAssistant: true,
              isCreatingAssistant: true,
              isUpdated: false
            })
          );
          dispatch(updateStarterQuestion(''));
          setSelectedDocs([]);
          setConversationStarters([]);
          setNewAssistant(defaultAssistantProps);
          formMethods.reset();
          dispatch(clearChatSnapshot(0));
          dispatch(setSelectedChat(undefined));
        }
      });
    }
  };

  const handleFileSelectionModalOpen = () => {
    setFileSelectionModalOpen(true);
  };
  const handleFileSelectionModalClose = () => {
    setFileSelectionModalOpen(false);
  };

  const toggleIconModal = () => {
    setIconModalPopupOpen((prev) => !prev);
  };
  const handleIconSelection = useCallback(() => {
    setIconModalPopupOpen(false);
    formMethods.setValue('assistantIcon', selectedIcon?.id);
    const temp = {
      ...tempObj,
      assistantIcon: selectedIcon?.icon
    };
    setIconSizeError(false);
    dispatch(updateInitiateAssistantState(temp));
  }, [selectedIcon]);

  const handleUploadIcon = (e) => {
    const file = e?.target?.files[0];

    if (file) {
      const fileSize = file.size / 1024; // size in KB
      if (fileSize > MAX_ICON_FILE_SIZE) {
        setIconSizeError(true);
      } else {
        setIconSizeError(false);
        let reader = new FileReader();
        reader.onloadend = function () {
          uploadIcon({
            params: {
              params: {
                icon_str: reader.result
              }
            },
            successMsg: t(T.iconUploadedSuccessfully)
          });
        };
        reader.readAsDataURL(e?.target?.files[0]);
      }
    }
  };

  const handleSelectedIcon = (icon) => {
    setSelectedIcon(icon);
  };

  const handleInstructionsModalOpen = (formInstructions) => {
    setNewAssistant({ ...newAssistant, instructions: formInstructions });
    setInstructionModalPopupOpen(true);
  };
  const handleInstructionsModalClose = (isCancel: boolean) => {
    setInstructionModalPopupOpen(false);
    if (!isCancel) {
      formMethods.setValue('instructions', newAssistant.instructions);
    }
  };

  const handleInstructionsChange = (e) => {
    setNewAssistant({ ...newAssistant, instructions: e.target.value });
  };

  const handleImportFiles = useCallback(() => {
    handleFileSelectionModalClose();
    const temp = {
      ...assistantObj,
      fileList: selectedDocs.map((doc) => ({
        rid: doc?.id || doc,
        type_id: doc.type_id || 11
      }))
    };
    dispatch(updateInitiateAssistantState(temp));
  }, [selectedDocs, assistantObj]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleOnCloseModal = () => {
    if (!compareAssistantObject(assistantObj, tempObj)) {
      setConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  const handleConfirmationAndCreateModalClose = () => {
    setConfirmationModalOpen(false);
    onClose();
  };
  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={isOpen}
        // onClose={onClose}
        PaperProps={{
          sx: {
            borderRadius: '0px !important',
            padding: '30px'
          }
        }}
        TransitionComponent={Transition}
      >
        <Box display={'flex'} alignItems={'center'} gap={'4px'}>
          <Button
            sx={{ cursor: 'pointer', padding: 0, minWidth: 0 }}
            onClick={handleOnCloseModal}
          >
            <ChevronLeft />
          </Button>
          {title}
        </Box>
        <Box display={'flex'} flexDirection={'column'}>
          <Box display="flex" flex={1} flexDirection="column" rowGap={'16px'}>
            <Divider sx={{ marginTop: '10px' }} />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '10px',
                width: '100%',
                height: '85vh'
              }}
            >
              <Box
                sx={{
                  width: '50%',
                  borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                  display: 'flex',
                  flexDirection: 'row',
                  rowGap: '8px',
                  alignSelf: 'flex-start',
                  height: '100%',
                  overflowY: 'scroll'
                }}
              >
                <CreateAssistantFormContainer>
                  <form
                    onKeyDown={handleKeyDown}
                    id="collection-form"
                    style={{
                      width: '70%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px'
                    }}
                  >
                    <Controller
                      name="assistantIcon"
                      control={control}
                      render={({ field, formState: { errors } }) => (
                        <Tooltip
                          title={t(field?.value ? T.changeImage : T.addImage)}
                          placement="top"
                        >
                          {field.value ? (
                            <IconImage
                              src={
                                icons?.filter(
                                  (icon) => icon.id == field.value
                                )?.[0]?.icon
                              }
                              alt="Assistant-Icon"
                              onClick={toggleIconModal}
                            />
                          ) : (
                            <Box
                              sx={{
                                padding: '15px 15px 10px 15px',
                                cursor: 'pointer',
                                border: '1px dashed #CDD3DC',
                                borderRadius: '5px',
                                alignSelf: 'center',
                                ':hover': {
                                  backgroundColor: 'lightgrey'
                                }
                              }}
                              onClick={toggleIconModal}
                            >
                              <AddIcon />
                            </Box>
                          )}
                        </Tooltip>
                      )}
                    />
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: {
                          value: true,
                          message: t(T.required, { name: t(T.assistantName) })
                        },
                        maxLength: {
                          value: MAX_NAME_LENGTH,
                          message: t(T.maxLength, {
                            name: t(T.assistantName),
                            length: MAX_NAME_LENGTH
                          })
                        }
                      }}
                      render={({ field, formState: { errors } }) => (
                        <>
                          <Typography variant="body1">
                            {t(T.name)}
                            <span style={{ color: 'red' }}>*</span>
                          </Typography>
                          <OutlinedInput
                            id="outlined-adornment-weight"
                            value={field.value || ''}
                            required
                            error={!!errors.name}
                            placeholder={t(T.namePlaceholder)}
                            inputProps={{
                              'aria-label': 'weight'
                            }}
                            {...field}
                          />
                          {errors.name?.message && (
                            <FormHelperText
                              id="outlined-weight-helper-text"
                              error={!!errors.name}
                            >
                              {errors.name?.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        maxLength: {
                          value: MAX_DESC_LENGTH,
                          message: t(T.maxLength, {
                            name: t(T.assistantDescription),
                            length: MAX_DESC_LENGTH
                          })
                        }
                      }}
                      render={({ field, formState: { errors } }) => (
                        <>
                          <Typography variant="body1">
                            {t(T.description)}
                          </Typography>
                          <TextField
                            sx={{
                              width: '100%'
                            }}
                            multiline
                            rows={3}
                            size="medium"
                            variant="outlined"
                            value={field.value || ''}
                            error={!!errors.description}
                            placeholder={t(T.descriptionPlaceholder)}
                            inputProps={{
                              style: {
                                padding: 0
                              }
                            }}
                            {...field}
                          />
                          {errors.description?.message && (
                            <FormHelperText
                              id="outlined-weight-helper-text"
                              error={!!errors.description}
                            >
                              {errors.description?.message}
                            </FormHelperText>
                          )}
                        </>
                      )}
                    />
                    <Controller
                      name="instructions"
                      control={control}
                      rules={{
                        maxLength: {
                          value: MAX_INSTRUCTIONS_LENGTH,
                          message: t(T.maxLength, {
                            name: t(T.assistantInstructions),
                            length: MAX_INSTRUCTIONS_LENGTH
                          })
                        }
                      }}
                      render={({ field, formState: { errors } }) => (
                        <>
                          <Typography variant="body1">
                            {t(T.instructions)}
                          </Typography>
                          <Box sx={{ position: 'relative', width: '100%' }}>
                            <TextField
                              sx={{
                                width: '100%'
                              }}
                              size="medium"
                              variant="outlined"
                              value={newAssistant.instructions || ''}
                              multiline
                              rows={5}
                              error={!!errors.instructions}
                              placeholder={t(T.instructionsPlaceholder)}
                              inputProps={{
                                style: {
                                  padding: 0,
                                  paddingRight: '24px'
                                }
                              }}
                              {...field}
                            />
                            {errors.instructions?.message && (
                              <FormHelperText
                                id="outlined-weight-helper-text"
                                error={!!errors.instructions}
                              >
                                {errors.instructions?.message}
                              </FormHelperText>
                            )}
                            <InputAdornment
                              position="end"
                              sx={{
                                position: 'absolute',
                                top: '106px',
                                right: '23px',
                                cursor: 'pointer'
                              }}
                              onClick={() =>
                                handleInstructionsModalOpen(field.value)
                              }
                            >
                              <FullScreen />
                            </InputAdornment>
                          </Box>
                        </>
                      )}
                    />
                    <Controller
                      name="conversationStarter"
                      control={control}
                      render={({ field, formState: { errors } }) => {
                        return (
                          <ConversationStarters
                            conversationStarters={conversationStarters}
                            setConversationStarters={setConversationStarters}
                            newAssistant={tempObj}
                            setStarterError={setStarterError}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="aiModels"
                      control={control}
                      render={({ field, formState: { errors } }) => {
                        return (
                          <AiModels
                            newAssistant={newAssistant}
                            setNewAssistant={setNewAssistant}
                            configuredModels={configuredModels}
                            errors={errors}
                          />
                        );
                      }}
                    />
                    <Controller
                      name="capabilities"
                      control={control}
                      render={({ field, formState: { errors } }) => {
                        return (
                          <Capabilities
                            newAssistant={newAssistant}
                            selectedDocs={selectedDocs}
                            setSelectedDocs={setSelectedDocs}
                            handleFileSelectionModalOpen={
                              handleFileSelectionModalOpen
                            }
                            isFileSelectionModalOpen={isFileSelectionModalOpen}
                            setNewAssistant={setNewAssistant}
                            setShowFilesGrid={setShowFilesGrid}
                            showFilesGrid={showFilesGrid}
                          />
                        );
                      }}
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isSubmitting}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 10,
                        backgroundColor: '#7BF3B9',
                        color: '#000C57',
                        '&:hover': {
                          color: '#000C57',
                          backgroundColor: '#7BF3B9'
                        }
                      }}
                    >
                      {assistantDetails ? t(T.update) : t(T.create)}
                    </Button>
                  </form>
                </CreateAssistantFormContainer>
              </Box>
              <Box
                sx={{
                  width: '50%',
                  alignSelf: 'flex-start'
                }}
              >
                <Typography textAlign={'center'} variant="h4">
                  {t(T.preview)}
                </Typography>
                <AzureWebPubSubChatProvider>
                  <ChatMessageList />
                </AzureWebPubSubChatProvider>
              </Box>
            </Box>
            {isIconModalPopupOpen && (
              <>
                <DialogLayout
                  dialogTitle={t(T.chooseEmojis)}
                  isOpen={isIconModalPopupOpen}
                  onClose={toggleIconModal}
                  handleConfirmation={handleIconSelection}
                  confirmationButtonText={t(T.done)}
                >
                  <Divider />
                  {iconSizeError && (
                    <Typography color={'red'} textAlign={'center'} mt={'10px'}>
                      {t(T.emojiDisclaimer)}
                    </Typography>
                  )}
                  <IconsContainer>
                    <Button
                      component="label"
                      role={undefined}
                      tabIndex={-1}
                      sx={{
                        border: '1px dashed #CDD3DC',
                        display: 'flex',
                        padding: '21px',
                        alignItems: 'center'
                      }}
                    >
                      <CloudUploadIcon />
                      <VisuallyHiddenInput
                        type="file"
                        accept=".png, .svg"
                        onChange={handleUploadIcon}
                      />
                    </Button>
                    {icons?.map((icon: any, index) => (
                      <Box
                        className="image-container"
                        key={index}
                        sx={{
                          display: 'flex',
                          width: '70px',
                          padding: '10px',
                          alignItems: 'center',
                          flexShrink: 0
                        }}
                      >
                        <img
                          src={icon?.icon}
                          className="default-image"
                          style={{
                            width: '50px',
                            height: '50px',
                            cursor: 'pointer'
                          }}
                          onClick={() => handleSelectedIcon(icon)}
                        ></img>
                        {selectedIcon?.icon === icon?.icon && (
                          <CheckCircleOutline
                            color="success"
                            style={{
                              width: '20px',
                              height: '20px',
                              position: 'absolute',
                              top: '5px',
                              right: '5px'
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </IconsContainer>
                </DialogLayout>
              </>
            )}
            {instructionModalPopupOpen && (
              <DialogLayout
                dialogTitle={t(T.instructions)}
                isOpen={instructionModalPopupOpen}
                onClose={() => handleInstructionsModalClose(true)}
                handleConfirmation={() => handleInstructionsModalClose(false)}
                confirmationButtonText={t(T.confirm)}
                showCloseButton={false}
              >
                <TextField
                  value={newAssistant.instructions}
                  onChange={handleInstructionsChange}
                  fullWidth
                  multiline
                  rows={10}
                ></TextField>
                {newAssistant?.instructions?.length >
                  MAX_INSTRUCTIONS_LENGTH && (
                  <Typography
                    variant="body1"
                    color="red"
                    mt={'10px'}
                    mb={'10px'}
                  >
                    {t(T.maxLength, {
                      name: t(T.assistantInstructions),
                      length: MAX_INSTRUCTIONS_LENGTH
                    })}
                  </Typography>
                )}
              </DialogLayout>
            )}
            {isConfirmationModalOpen && (
              <DialogLayout
                dialogTitle={t(T.createUpdateConfirmationDialogTitle)}
                isOpen={isConfirmationModalOpen}
                onClose={handleConfirmationAndCreateModalClose}
                closeModal={handleConfirmationModalClose}
                handleConfirmation={handleConfirmationModalClose}
                confirmationButtonText={t(T.stayOnThisPage)}
                cancelButtonText={t(T.leaveWithoutSaving)}
              >
                <Box>{t(T.createUpdateConfirmationDisclaimer)}</Box>
              </DialogLayout>
            )}
            <DocumentsContextProvider>
              {isFileSelectionModalOpen && (
                <ImportFiles
                  open={isFileSelectionModalOpen}
                  handleClose={handleFileSelectionModalClose}
                  selectedDocs={selectedDocs}
                  setSelectedDocs={setSelectedDocs}
                  handleImportFiles={handleImportFiles}
                />
              )}
            </DocumentsContextProvider>
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateAssistantForm;
