import { IconButton } from '@mui/material';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { CommandComponentProps } from 'src/types/custom_component';
import { useCallback } from 'react';

const RowAction = ({ onExecute }: CommandComponentProps) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();

      onExecute();
    },
    [onExecute]
  );

  return (
    <IconButton onClick={handleDelete} title={t(T.delete)}>
      <DeleteIcon />
    </IconButton>
  );
};

export default RowAction;
