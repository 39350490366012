import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as DefaultIcon } from 'src/assets/assistantIcons/default-icon.svg';
import { ReactComponent as ChatGptLogo } from 'src/assets/chatgpt-logo-filled.svg';
import { ReactComponent as ShareChatIcon } from 'src/assets/documentIcons/Share.svg';
import { ReactComponent as EmpowerGptLogo } from 'src/assets/Enterprise-logo.svg';
import { ONBOARDING_STEPS_TARGET } from 'src/components/UserOnboard/constants';
import { TENANT_NAME } from 'src/config';
import {
  resetAssistantContext,
  startChat
} from 'src/content/Documents/utils/gridUtils';
import { AzureWebPubSubChatContext } from 'src/contexts/AzureWebPubSubChatContext';
import { SidebarContext } from 'src/contexts/SidebarContext';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import {
  drawerWidth,
  miniDrawerWidth
} from 'src/layouts/AccentHeaderLayout/styles';
import {
  updateAssistantContext,
  updateInitiateAssistantState
} from 'src/redux/slices/assistant';
import {
  appendChat,
  clearChatSnapshot,
  createChatSnapshot,
  createShareChat,
  removeOpenedDocument,
  setSelectedChat,
  updateChatData,
  updateStreamEventTimings
} from 'src/redux/slices/chat';
import { updateOnboarding } from 'src/redux/slices/onboarding';
import { setAlert } from 'src/redux/slices/snackbar';
import { RootState, useDispatch } from 'src/redux/store';
import {
  useLazyGetAssistantDetailQuery,
  useLazyGetMessagesQuery,
  useShareChatMutation
} from 'src/services/api';
import { DataApiInputParams } from 'src/types/api';
import { StringKeys } from 'src/types/base';
import {
  ALERT,
  CHAT_CLASS,
  CHAT_SOURCE_TYPES,
  OnboardingStepTypes,
  RESOURCE_TYPE
} from 'src/types/enum';
import {
  ErrorCodes,
  ErrorContext,
  ErrorMessages
} from 'src/utils/errorMappings';
import logger from 'src/utils/logger';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import QueryBox from '../QueryBox';
import { DisclaimerTextButton, EmpowerGPTLogoBox } from '../styles';
import { MESSAGE_TYPE } from '../types';
import {
  MESSAGE_PAGE_SIZE,
  chatErrors,
  getChatMessagesApiParams,
  getChatTypeText,
  getShareChatApiParams,
  isDifferentDay
} from '../utils';
import AppVersion from './AppVersion';
import ChatContent from './ChatContent';
import ChatResponse from './ChatResponse';
import MultiTabsPdfViewer from './MultiTabsPdfViewer';
import Reaction from './Reaction';
import ShareChatModal from './ShareChat/ShareChatModal';
import WelcomeMessage from './WelcomeMessage';
import FilePermissionModal from 'src/content/Assistant/Components/FilePermissionModal';

function ChatMessageList() {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);
  const prevHeight = useRef<number>(0);
  const [page, setPage] = useState<number>(0);
  const [currentPageLength, setCurrentPageLength] = useState<number>(0);
  const [messages, setMessages] = useState([]);
  const [isTyping, setTyping] = useState<boolean>(false);
  const [isResizeActive, setIsResizeActive] = useState<boolean>(false);
  const [isChatInHistory, setIsChatInHistory] = useState<boolean>(false);
  const [isFileModalOpen, setFileModalOpen] = useState<boolean>(false);

  const [width, setWidth] = useState<number>(0.4 * window.innerWidth);
  const [responseMeta, setResponseMeta] = useState(null);
  const { isOnboarded, configuredModels, icons } = useSelector(
    (state: RootState) => state.data
  );
  const [currentAssistantDetails, setCurrentAssistantDetails] =
    useState<StringKeys>();
  const { assistantContext, assistantObj } = useSelector(
    (state: RootState) => state.assistant
  );
  const { stepCount, stepsContext } = useSelector(
    (state: RootState) => state.onboarding
  );

  const [isConnectionEstablished, setIsConnectionEstablished] =
    useState<boolean>(false);
  const [isManualScrolling, setIsManualScrolling] = useState(false);
  const messagesRef = useRef(null);
  const generatingResponseRef = useRef(null);
  const {
    selectedChat: chatId,
    chatContexts,
    chats
  } = useSelector((state: RootState) => state.chat);
  const dispatch = useDispatch();
  const { id: paramsId } = useParams();
  const {
    streamMessage,
    chatMeta,
    error: streamingError
  } = useContext(AzureWebPubSubChatContext);
  const { sidebarToggle } = useContext(SidebarContext);
  const baseHeight = `calc(100% - 166px)`;
  const [isOpenShareChatModal, setIsOpenShareChatModal] =
    useState<boolean>(false);
  const [
    fetchChatMessages,
    { data: chatMessages, isLoading, isFetching, isError }
  ] = useLazyQuery<DataApiInputParams, StringKeys>({
    api: useLazyGetMessagesQuery,
    errorContext: ErrorContext.CHAT
  });
  const [
    fetchAssistantDetail,
    {
      isFetching: isFetchingAssistant,
      isLoading: isLoadingAssistants,
      data: assistantData
    }
  ] = useLazyQuery({
    api: useLazyGetAssistantDetailQuery,
    errorContext: ErrorContext.ASSISTANT
  });
  const [
    shareChat,
    { data: shareChatObj, isLoading: isSharingChat, isError: isShareChatError }
  ] = useMutation({
    api: useShareChatMutation,
    errorContext: ErrorContext.GENERAL
  });
  const isInternetConnected = useMemo(
    () => !!window?.navigator?.onLine,
    [window?.navigator?.onLine]
  );
  const currentChat = useMemo(() => {
    const chat = chats.find((c) => c?.id === chatId);
    if (!chat) {
      setIsChatInHistory(false);
      return { ...chatContexts[0], subject: '' };
    }
    setIsChatInHistory(true);
    return chat;
  }, [chatId, chats, chatContexts]);

  const isAssistantDeleted = useMemo(() => {
    return (
      (currentChat?.chat_class === CHAT_CLASS.ASSISTANT &&
        currentChat?.rid === null) ||
      currentChat?.rid === 0
    );
  }, [currentChat?.chat_class, currentChat?.rid]);

  const isAssistantUpdated = useMemo(() => {
    if (!currentChat?.rid) return false;
    const chatTimeStamp = new Date(currentChat?.ts).getTime();
    const assistantModifiedOn = new Date(
      currentAssistantDetails?.modified_on
    ).getTime();
    return assistantModifiedOn > chatTimeStamp;
  }, [currentChat?.rid, currentChat?.ts, currentAssistantDetails?.modified_on]);

  const isAssistantHasModel = useMemo(() => {
    return (
      currentChat?.chat_class === CHAT_CLASS.ASSISTANT &&
      !!currentAssistantDetails?.aiModel
    );
  }, [currentChat?.chat_class, currentAssistantDetails?.aiModel]);

  const isAssistantHasFiles = useMemo(() => {
    const isAssistantChat = currentChat?.chat_class === CHAT_CLASS.ASSISTANT;
    const hasWebKnowledge =
      currentAssistantDetails?.isWebSearch ||
      currentAssistantDetails?.worldKnowledge;
    const hasFiles =
      !!currentAssistantDetails?.fileList?.length ||
      !!currentAssistantDetails?.permission?.resources?.length;
    return isAssistantChat && (hasWebKnowledge || hasFiles);
  }, [
    currentChat?.chat_class,
    currentAssistantDetails?.fileList,
    currentAssistantDetails?.worldKnowledge,
    currentAssistantDetails?.isWebSearch,
    currentAssistantDetails?.permission?.resources
  ]);

  const isAssistantUnusable = useMemo(() => {
    return (
      currentChat?.chat_class === CHAT_CLASS.ASSISTANT &&
      (isAssistantDeleted ||
        isAssistantUpdated ||
        !isAssistantHasModel ||
        !isAssistantHasFiles)
    );
  }, [
    currentChat?.chat_class,
    isAssistantDeleted,
    isAssistantUpdated,
    isAssistantHasModel,
    isAssistantHasFiles
  ]);

  useEffect(() => {
    if (assistantObj?.id) {
      setCurrentAssistantDetails(assistantObj);
    }
  }, [assistantObj?.id]);

  const getConversationBoxHeight = () => {
    const isDocumentViewerOpen = chatContexts[chatId]?.isDocumentViewerOpen;

    return {
      [theme.breakpoints.up('xl')]: {
        height: isDocumentViewerOpen ? `calc(100% - 190px)` : baseHeight
      },
      [theme.breakpoints.down('xl')]: {
        height: isDocumentViewerOpen ? `calc(100% - 200px)` : baseHeight
      }
    };
  };

  const handleOpenShareChatModal = useCallback(async () => {
    const chatContext = chatContexts?.[chatId]?.shareChatData;
    const lastMessageTimeStamp = new Date(messages?.[messages.length - 1]?.ts);
    const shareLinkTimeStamp = new Date(chatContext?.sharedOn);
    if (!chatContext?.link || lastMessageTimeStamp > shareLinkTimeStamp) {
      await shareChat({
        params: getShareChatApiParams(chatId)
      });
    } else {
      setIsOpenShareChatModal(true);
    }
  }, [chatContexts?.[chatId]?.shareChatData, messages]);

  const handleCloseShareChatModal = useCallback((e) => {
    e.stopPropagation();
    setIsOpenShareChatModal(false);
  }, []);

  useEffect(() => {
    if (shareChatObj && !isShareChatError) {
      setIsOpenShareChatModal(true);
      dispatch(
        createShareChat({
          chatId,
          shareChatData: {
            link: shareChatObj?.data[0]?.share_id,
            sharedOn: shareChatObj?.data[0]?.shared_on
          }
        })
      );
    }
  }, [shareChatObj, isShareChatError]);

  useEffect(() => {
    // resetting on chat selection change
    setMessages([]);
    setPage(0);
    setWidth(0.4 * window.innerWidth);
    setIsResizeActive(false);
    setTyping(false);
    setCurrentPageLength(0);
    setResponseMeta(null);
    if (paramsId !== undefined) {
      fetchChat(parseInt(paramsId), 0);
    }
  }, [paramsId]);

  const handleQuerySend = useCallback(
    (data, isError) => {
      setTyping(true);
      if (isError) {
        dispatch(
          setAlert({
            msg: t(T.errorGeneratingResponse),
            type: ALERT.ERROR
          })
        );
      } else {
        setMessages((prevMsgs) => [...prevMsgs, ...data]);
      }
    },
    [chatId]
  );

  const handleResponseReceive = useCallback(
    (data, isError) => {
      if (isError) {
        setTyping(false);
      } else {
        if (data) setIsConnectionEstablished(true);
        setMessages((prevMsgs) => {
          const updatedMsgs = [...prevMsgs];
          const lastIndex = updatedMsgs.length - 1;
          updatedMsgs[lastIndex] = {
            ...prevMsgs[lastIndex],
            id: data?.[0]?.parent_id
          };
          return updatedMsgs;
        });
        if (chatId === 0) {
          const id = data?.[0]?.chat_id;
          setTyping(true);
          dispatch(
            createChatSnapshot({
              chatId: assistantContext?.isPreview ? 0 : id,
              chatContext: chatContexts[0]
            })
          );
          dispatch(setSelectedChat(assistantContext?.isPreview ? 0 : id));
          if (!assistantContext?.isPreview) {
            dispatch(appendChat(data?.[0]?.chat_data));
            dispatch(clearChatSnapshot(0));
            window.history.pushState(
              {},
              '',
              `${window.location.pathname}/${id}`
            );
          }
        } else {
          if (currentChat?.web_search_enabled !== data?.[0]?.web_search_enabled)
            dispatch(
              updateChatData({
                chatId: chatId,
                chatData: { web_search_enabled: data?.[0]?.web_search_enabled }
              })
            );
        }
        setResponseMeta(data?.[0]);
      }
    },
    [chatId, chatContexts, assistantContext?.isPreview]
  );

  useEffect(() => {
    const isConversationContextChanged =
      currentChat?.chat_class === CHAT_CLASS.ASSISTANT &&
      currentChat?.rid &&
      assistantObj?.id !== currentChat?.rid &&
      chatId !== currentAssistantDetails?.chat_id;

    const shouldResetAssistantContext =
      currentChat &&
      isChatInHistory &&
      currentChat?.rid !== currentAssistantDetails?.id &&
      assistantObj?.id !== currentChat?.rid;

    if (isConversationContextChanged) {
      fetchAssistantDetail({
        params: {
          params: {
            assistant_id: currentChat?.rid,
            fetch_all: false
          }
        }
      });
    } else if (shouldResetAssistantContext) {
      setCurrentAssistantDetails({});
      if (
        currentChat?.chat_class === CHAT_CLASS.ASSISTANT &&
        (currentChat?.rid === null || currentChat?.rid === 0)
      ) {
        resetAssistantContext(dispatch, true);
      } else {
        resetAssistantContext(dispatch);
      }
    }
  }, [
    currentChat,
    isChatInHistory,
    currentAssistantDetails?.chat_id,
    currentAssistantDetails?.id,
    assistantObj?.id,
    chatId
  ]);

  useEffect(() => {
    if (
      currentChat.chat_class === CHAT_CLASS.ASSISTANT &&
      !isFetchingAssistant &&
      assistantData?.data
    ) {
      if (currentChat?.rid === null || currentChat?.rid === 0) {
        setCurrentAssistantDetails({});
      } else {
        setCurrentAssistantDetails({
          ...assistantData?.data?.[0],
          chat_id: chatId
        });
      }

      dispatch(
        updateAssistantContext({
          ...assistantContext,
          isAssistant: true,
          isStartChat: true,
          isPreview: false
        })
      );
      dispatch(
        updateInitiateAssistantState({
          ...assistantData?.data?.[0],
          isWebSearch: assistantData?.data?.[0]?.settings?.web_search_enabled,
          worldKnowledge: assistantData?.data?.[0]?.settings?.world_knowledge,
          aiModel: configuredModels.filter(
            (model) =>
              model.id === parseInt(assistantData?.data?.[0]?.settings.model_id)
          )[0]
        })
      );
    }
  }, [assistantData, isFetchingAssistant, currentChat?.rid]);

  useEffect(() => {
    if (chatId !== 0 && chatId !== undefined) {
      setIsManualScrolling(false);
      fetchChat(chatId);
    }
  }, [page]);

  useEffect(() => {
    if (chatMessages?.data) {
      if (page === 0) {
        setMessages([...chatMessages.data]);
      } else {
        setMessages((prevData) => [...chatMessages.data, ...prevData]);
      }
      setCurrentPageLength(chatMessages?.data?.length || 0);
    }
  }, [chatMessages?.data]);

  useEffect(() => {
    if (isError && isInternetConnected) {
      dispatch(
        setAlert({
          msg: t(T.messagesLoadingFailed),
          type: ALERT.ERROR
        })
      );
    }
  }, [isError, isInternetConnected]);

  useEffect(() => {
    if (streamMessage) {
      if (isTyping) {
        const streamingStartTime = new Date();
        dispatch(
          updateStreamEventTimings({
            chatId,
            streamEventTimings: {
              ...chatContexts[chatId]?.streamEventTimings,
              responseStreamStartTime: streamingStartTime.getTime()
            }
          })
        );
        logger.log(
          '%c Streaming start time',
          'color: orange;',
          streamingStartTime
        );
        logger.log(
          '%c Time Duration from query sent to streaming start time',
          'color: red;',
          (streamingStartTime.getTime() -
            chatContexts[chatId]?.streamEventTimings?.querySendTime) /
            1000,
          'seconds'
        );
        logger.log(
          '%c Time Duration after query acknowledgement to streaming start time',
          'color: red;',
          (streamingStartTime.getTime() -
            chatContexts[chatId]?.streamEventTimings?.queryAcknowledgeTime) /
            1000,
          'seconds'
        );
        setMessages((prevMsgs) => [
          ...prevMsgs,
          { ...responseMeta, message: streamMessage }
        ]);
        setTyping(false);
        setIsConnectionEstablished(false);
      } else {
        setIsConnectionEstablished(false);
        setMessages((prevMsgs) => {
          const updatedMsgs = [...prevMsgs];
          updatedMsgs[updatedMsgs.length - 1] = {
            ...prevMsgs[prevMsgs.length - 1],
            message: streamMessage,
            ...(assistantContext?.isAssistant &&
              assistantContext?.isPreview && {
                type: MESSAGE_TYPE.R
              })
          };
          return updatedMsgs;
        });
      }
    }
  }, [streamMessage]);

  useEffect(() => {
    if (streamMessage?.length === 0 && isConnectionEstablished) {
      const timeoutId = setTimeout(() => {
        setIsConnectionEstablished(false);
        dispatch(
          setAlert({
            msg: t(T.errorGeneratingResponse),
            type: ALERT.ERROR
          })
        );
        setTyping(false);
        setResponseMeta(null);
        if (!isOnboarded && stepCount === 2 && stepsContext === 'chat') {
          dispatch(
            updateOnboarding({
              isOnboarding: false,
              stepsContext: OnboardingStepTypes.DOCUMENT
            })
          );
        }
      }, 60000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [streamMessage, isConnectionEstablished]);

  const updateLastMessage = useCallback((updateObj) => {
    setMessages((prevMsgs) => {
      const updatedMsgs = [...prevMsgs];
      const lastMsgIndex = updatedMsgs.length - 1;

      if (lastMsgIndex >= 0) {
        updatedMsgs[lastMsgIndex] = {
          ...updatedMsgs[lastMsgIndex],
          ...updateObj
        };
      }

      return updatedMsgs;
    });
  }, []);

  useEffect(() => {
    if (chatMeta) {
      const streamingEndTime = new Date();
      logger.log('%c Streaming end time', 'color: orange;', streamingEndTime);
      logger.log(
        '%c Total streaming time',
        'color: red;',
        (streamingEndTime.getTime() -
          chatContexts[chatId]?.streamEventTimings?.responseStreamStartTime) /
          1000,
        'seconds'
      );
      dispatch(
        updateStreamEventTimings({
          chatId,
          streamEventTimings: {}
        })
      );
      setResponseMeta(null);
      if (chatMeta?.new_subject) {
        dispatch(
          updateChatData({
            chatId: chatId,
            chatData: { subject: chatMeta?.new_subject }
          })
        );
      }
      if (chatMeta?.msg?.files && chatMeta?.msg?.files.length) {
        updateLastMessage({
          files: [...chatMeta?.msg?.files],
          status_details: null
        });
      } else {
        updateLastMessage({ status_details: null });
      }
    }
    if (
      !isOnboarded &&
      chatMeta?.new_subject &&
      stepCount === 2 &&
      stepsContext === 'chat'
    ) {
      setTimeout(() => {
        dispatch(
          updateOnboarding({
            isOnboarding: false,
            stepsContext: OnboardingStepTypes.DOCUMENT
          })
        );
      }, 2000);
    }
  }, [chatMeta]);

  useEffect(() => {
    if (streamingError) {
      setIsConnectionEstablished(false);
      setTyping(false);
      setResponseMeta(null);
      if (chatErrors.includes(streamingError?.msg?.code?.toUpperCase())) {
        setMessages((prevMsgs) => {
          const updatedMsgs = [...prevMsgs];
          const lastMsg = prevMsgs[prevMsgs.length - 1];
          const secondLastMsg = prevMsgs[prevMsgs.length - 2];
          updatedMsgs.push({
            ...secondLastMsg,
            message: t(
              ErrorMessages[ErrorContext.AI]?.[
                streamingError?.msg?.code?.toUpperCase()
              ] || ErrorMessages[ErrorContext.AI][ErrorCodes.GENERAL_AI_ERROR]
            ),
            ts: lastMsg?.ts,
            type: 'r'
          });
          return updatedMsgs;
        });
      } else {
        dispatch(
          setAlert({
            msg: t(
              ErrorMessages[ErrorContext.AI]?.[
                streamingError?.msg?.code?.toUpperCase()
              ] || ErrorMessages[ErrorContext.AI][ErrorCodes.GENERAL_AI_ERROR]
            ),
            type: ALERT.ERROR,
            hideDuration: 10000
          })
        );
      }
      if (
        !isOnboarded &&
        stepCount === 2 &&
        stepsContext === OnboardingStepTypes.CHAT
      ) {
        dispatch(
          updateOnboarding({ stepsContext: OnboardingStepTypes.DOCUMENT })
        );
      }
    }
  }, [streamingError]);

  useEffect(() => {
    if (!isTyping && !isManualScrolling) {
      handleAutoScroll();
    }
  }, [messages, isTyping, isManualScrolling]);
  useEffect(() => {
    if (isTyping) {
      setIsManualScrolling(false);
      generatingResponseRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'end'
      });
    }
  }, [isTyping]);

  const fetchChat = (id: number, pageNum?: number) => {
    fetchChatMessages({
      params: getChatMessagesApiParams(id, pageNum || page)
    });
  };

  useEffect(() => {
    const element = document.querySelector(
      `[class^="${chatContexts[chatId]?.messageId}"]`
    );
    if (chatContexts[chatId]?.isDocumentViewerOpen) {
      element?.scrollIntoView({
        block: 'end',
        behavior: 'auto'
      });
    } else {
      scrollRef?.current?.scrollIntoView({
        behavior: 'auto',
        block: 'nearest'
      });
    }
  }, [chatContexts[chatId]?.isDocumentViewerOpen]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    if (!scrollRef.current) return;
    const isScrollAtBottom =
      scrollRef.current.scrollHeight - scrollRef.current.scrollTop ===
      scrollRef.current.clientHeight;
    if (isScrollAtBottom) {
      setIsManualScrolling(false);
    } else {
      setIsManualScrolling(true);
    }
    const { scrollTop, scrollHeight } = scrollRef.current;
    if (scrollTop === 0) {
      if (currentPageLength === MESSAGE_PAGE_SIZE)
        setPage((prevPage) => prevPage + 1);
      prevHeight.current = scrollHeight;
    }
  };

  const handleAutoScroll = (isTyping = false, range = 0) => {
    messagesRef?.current?.scrollIntoView({ behavior: 'auto', block: 'end' });
  };

  const toggleIsResizeActive = useCallback(() => {
    setIsResizeActive((prevVal) => !prevVal);
  }, []);

  const handleResize = (_, { size }) => {
    setWidth(size.width);
  };

  const handleOnDocumentClose = useCallback(
    ({ resourceId, documentIndex }) =>
      dispatch(
        removeOpenedDocument({
          chatId: resourceId,
          documentIndex: documentIndex
        })
      ),
    []
  );

  const chatTypeText = useMemo(
    () =>
      getChatTypeText(
        currentChat?.chat_class,
        currentChat?.chat_type,
        currentAssistantDetails,
        t,
        currentChat?.rid
      ),
    [
      currentChat?.chat_class,
      currentChat?.chat_type,
      currentChat?.rid,
      currentAssistantDetails
    ]
  );

  const chatIcon = useMemo(() => {
    const iconSize = { width: '40px', height: '40px', borderRadius: '10%' };
    const defaultIconStyle = {
      ...iconSize,
      padding: '10px',
      backgroundColor: 'rgba(222, 224, 229, 0.70)'
    };

    const assistantIconId =
      currentAssistantDetails?.settings?.icon_id ||
      assistantObj?.settings?.icon_id;

    // This checks if the chat was initiated as a new chat (start chat), in which case it checks assistantObj.
    // If the chat was initiated from chat history, it checks the chat_class.
    const isAssistantChat =
      currentChat?.chat_class === CHAT_CLASS.ASSISTANT ||
      assistantContext?.isAssistant;

    if (isAssistantChat) {
      return assistantIconId && !isAssistantDeleted ? (
        <img
          src={
            icons.find((icon) => icon.id === parseInt(assistantIconId))?.icon
          }
          alt="Assistant-Icon"
          style={iconSize}
        />
      ) : (
        <DefaultIcon style={defaultIconStyle} />
      );
    }

    if (currentChat?.chat_type === CHAT_SOURCE_TYPES.ChatGPT) {
      return <ChatGptLogo width={40} height={40} />;
    }

    return (
      <EmpowerGPTLogoBox>
        <EmpowerGptLogo />
      </EmpowerGPTLogoBox>
    );
  }, [
    currentChat,
    currentAssistantDetails,
    assistantObj?.settings?.icon_id,
    assistantContext?.isAssistant,
    isAssistantDeleted
  ]);

  const handleCloseFileModal = useCallback(() => setFileModalOpen(false), []);

  const handleStartNewChat = useCallback(() => {
    if (
      (currentAssistantDetails?.settings?.web_search_enabled ||
        currentAssistantDetails?.has_permission ||
        currentAssistantDetails?.settings?.world_knowledge) &&
      currentAssistantDetails?.aiModel
    ) {
      startChat({
        documentContext: {
          id: currentAssistantDetails?.id,
          name: currentAssistantDetails?.name,
          assistant_id: currentAssistantDetails?.id
        },
        dispatch,
        navigate,
        selectedChat: chatId,
        resourceType: RESOURCE_TYPE.ASSISTANT
      });
    } else {
      setFileModalOpen(true);
    }
  }, [currentAssistantDetails, chatId]);

  const pdfRender = useMemo(
    () => (
      <Paper
        elevation={0}
        style={{
          overflow: 'hidden',
          position: 'relative',
          width: width,
          padding: 6,
          height: '100%'
        }}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            height: '85vh',
            margin: '6px 6px'
          }
        })}
      >
        <MultiTabsPdfViewer
          selectedDocumentIndex={chatContexts?.[chatId]?.selectedDocumentIndex}
          pdfDocuments={chatContexts?.[chatId]?.openedDocuments}
          resourceId={chatId}
          onDocumentRemove={handleOnDocumentClose}
        />
      </Paper>
    ),
    [
      width,
      chatContexts?.[chatId]?.selectedDocumentIndex,
      chatContexts?.[chatId]?.openedDocuments
    ]
  );

  if (
    isLoading ||
    !currentChat ||
    isLoadingAssistants ||
    isFetchingAssistant ||
    (!assistantContext?.isPreview && chatId === undefined)
  ) {
    return <Loader />;
  }

  if (!isLoading && isFetching) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <CircularProgress
          size={40}
          sx={{
            color: theme.colors.primary.main,
            margin: 'auto'
          }}
        />
      </Box>
    );
  }

  return (
    <Stack
      direction="row"
      sx={{
        height: assistantContext?.isPreview ? '85vh' : '100vh',
        overflow: 'hidden'
      }}
    >
      <Box
        height={'100%'}
        width={
          !!chatContexts?.[chatId]?.openedDocuments?.length
            ? window.innerWidth - width
            : '100%'
        }
      >
        <Box
          className={ONBOARDING_STEPS_TARGET.CHAT_MESSAGES}
          sx={{
            width: '100%',
            overflow: 'auto',
            position: 'relative',
            ...(!isAssistantUnusable && {
              ...getConversationBoxHeight()
            }),
            ...(isAssistantUnusable && {
              height: '100%'
            })
          }}
          onScroll={handleScroll}
          ref={scrollRef}
        >
          {!messages.length ? (
            <WelcomeMessage />
          ) : (
            <>
              {!assistantContext?.isPreview && (
                <AppBar
                  sx={{
                    width: `calc(100% - ${
                      sidebarToggle ? drawerWidth : miniDrawerWidth
                    }px)`
                  }}
                >
                  <Toolbar
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingLeft: 5,
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box display="flex" flexDirection="row" alignItems="center">
                      {chatIcon}
                      <Typography
                        fontSize={22}
                        fontWeight={500}
                        lineHeight={'28px'}
                        sx={{ marginLeft: 1.5 }}
                        title={currentChat?.subject || ''}
                        maxHeight={30}
                        maxWidth={'100%'}
                        textOverflow={'ellipsis'}
                        overflow="clip"
                      >
                        {`${chatTypeText} - ${currentChat?.subject}` || ''}
                      </Typography>
                    </Box>
                    <LoadingButton
                      loading={isSharingChat}
                      sx={{ minWidth: 0 }}
                      onClick={handleOpenShareChatModal}
                    >
                      {!isSharingChat && <ShareChatIcon />}
                    </LoadingButton>
                    {!isShareChatError && (
                      <ShareChatModal
                        isOpen={isOpenShareChatModal}
                        onClose={handleCloseShareChatModal}
                      />
                    )}
                  </Toolbar>
                </AppBar>
              )}
              <Box
                sx={(theme) => ({
                  marginTop: `${theme.mixins.toolbar.minHeight}px`
                })}
              >
                {messages.map((message, index) => (
                  <Box ref={messagesRef} key={index}>
                    <Grid
                      container
                      width={'100%'}
                      display="flex"
                      bgcolor={
                        message.type === MESSAGE_TYPE.Q
                          ? (theme) => theme.colors.custom.queryBg
                          : (theme) => theme.colors.custom.responseBg
                      }
                      sx={{
                        [theme.breakpoints.down('md')]: {
                          flexDirection: 'column'
                        },
                        [theme.breakpoints.up('xl')]: {
                          px: chatContexts[chatId].isDocumentViewerOpen
                            ? `10%`
                            : `15%`
                        },
                        [theme.breakpoints.down('xl')]: {
                          px: chatContexts[chatId].isDocumentViewerOpen
                            ? `5%`
                            : `10%`
                        }
                      }}
                    >
                      <Grid item flex={'1'} xs>
                        <ChatContent
                          message={message}
                          icons={icons}
                          currentAssistantDetails={currentAssistantDetails}
                          isDifferentDay={
                            message.ts && !assistantContext?.isPreview
                              ? isDifferentDay(
                                  messages[index - 1]?.ts || '',
                                  message.ts
                                )
                              : false
                          }
                          isStreamingResponse={
                            index === messages.length - 1 &&
                            responseMeta !== null
                          }
                        />
                      </Grid>
                      {!assistantContext?.isPreview && (
                        <Grid item mt={5} display={'flex'} alignItems={'top'}>
                          <Reaction
                            message={message}
                            id={message.id}
                            visibility={
                              message.type === MESSAGE_TYPE.R &&
                              message.status_details === null
                            }
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                ))}
              </Box>
            </>
          )}

          {isTyping && (
            <Grid
              container
              width={'100%'}
              display="flex"
              bgcolor={(theme) => theme.colors.custom.responseBg}
              sx={{
                [theme.breakpoints.down('md')]: {
                  flexDirection: 'column'
                },
                [theme.breakpoints.up('xl')]: {
                  px: chatContexts[chatId].isDocumentViewerOpen ? `10%` : `15%`
                },
                [theme.breakpoints.down('xl')]: {
                  px: chatContexts[chatId].isDocumentViewerOpen ? `5%` : `10%`
                }
              }}
            >
              <Grid item flex={'1'} xs>
                <Box
                  ref={generatingResponseRef}
                  display="flex"
                  alignItems="flex-start"
                  bgcolor={theme.colors.custom.responseBg}
                  sx={{
                    [theme.breakpoints.up('md')]: {
                      pl: 6,
                      pr: 2
                    },
                    [theme.breakpoints.down('md')]: {
                      px: 5
                    },
                    [theme.breakpoints.down('sm')]: {
                      px: 1
                    }
                  }}
                  py={3}
                >
                  <ChatResponse
                    icons={icons}
                    currentAssistantDetails={currentAssistantDetails}
                  />
                </Box>
              </Grid>
              <Grid
                item
                mt={5}
                display={'flex'}
                alignItems={'top'}
                justifyContent={'center'}
              >
                <Reaction id={0} visibility={false} />
              </Grid>
            </Grid>
          )}
        </Box>
        <Paper
          className="Query-Box-Paper"
          sx={{
            position: 'sticky',
            bottom: 0,
            width: '100%',
            justifyContent: 'center',
            pt: '2rem',
            ...((isAssistantDeleted ||
              isAssistantUpdated ||
              !isAssistantHasModel ||
              !isAssistantHasFiles) && {
              paddingY: '1rem'
            }),
            [theme.breakpoints.up('xl')]: {
              px: chatContexts[chatId].isDocumentViewerOpen ? `10%` : `15%`
            },
            [theme.breakpoints.down('xl')]: {
              px: chatContexts[chatId].isDocumentViewerOpen ? `5%` : `10%`
            }
          }}
        >
          {!isAssistantUnusable ? (
            <>
              <QueryBox
                messageList={messages}
                onQuerySend={handleQuerySend}
                onResponseReceive={handleResponseReceive}
                isStreamingResponse={responseMeta}
                currentAssistantDetails={currentAssistantDetails}
              />
              <AppVersion
                decreaseMaxWidth={chatContexts[chatId].isDocumentViewerOpen}
              />
            </>
          ) : (
            <Box
              sx={{
                textAlign: 'center',
                padding: 1
              }}
            >
              {isAssistantDeleted ? (
                <Typography variant="body1" color={'rgba(34, 47, 89, 0.80)'}>
                  {t(T.assistantDeletedDisclaimer)}
                </Typography>
              ) : (
                (isAssistantUpdated ||
                  !isAssistantHasModel ||
                  !isAssistantHasFiles) && (
                  <Typography variant="body1" color={'rgba(34, 47, 89, 0.80)'}>
                    {t(T.outdatedVersionText)}
                    <DisclaimerTextButton
                      variant="text"
                      onClick={handleStartNewChat}
                    >
                      {t(T.newChat)}
                    </DisclaimerTextButton>
                    {t(T.updatedAssistant)}
                  </Typography>
                )
              )}
            </Box>
          )}
        </Paper>
      </Box>
      {chatContexts[chatId].isDocumentViewerOpen && (
        <Box
          sx={(theme) => ({
            height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
            marginTop: theme.mixins.toolbar.minHeight + 'px'
          })}
        >
          <Resizable
            width={width}
            height={0}
            onResize={handleResize}
            onResizeStart={toggleIsResizeActive}
            onResizeStop={toggleIsResizeActive}
            resizeHandles={['w']}
            axis={'x'}
            handle={
              <div>
                <span
                  style={{
                    height: '100%',
                    width: !isResizeActive ? '20px' : '100%',
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                    zIndex: 1,
                    userSelect: 'none',
                    cursor: 'col-resize'
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            }
            minConstraints={[0.3 * window.innerWidth]}
            maxConstraints={[0.7 * window.innerWidth]}
            draggableOpts={{ enableUserSelectHack: false }}
          >
            {pdfRender}
          </Resizable>
        </Box>
      )}
      {isFileModalOpen && (
        <FilePermissionModal
          isFileModalOpen={isFileModalOpen}
          handleCloseFileModal={handleCloseFileModal}
          rowData={currentAssistantDetails}
        />
      )}
    </Stack>
  );
}

export default ChatMessageList;

const Loader = () => (
  <Box
    sx={{
      width: '100%',
      height: '100vh'
    }}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <CircularProgress size={40} disableShrink thickness={2} />
  </Box>
);
