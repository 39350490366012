import { IconButton } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ShareIcon } from 'src/assets/documentIcons/Share.svg';
import { ReactComponent as CloneIcon } from 'src/assets/clone.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/documentIcons/delete-icon.svg';
import { ReactComponent as EditIcon } from 'src/assets/edit.svg';
import { CommandComponentProps } from 'src/types/custom_component';
import { ACTION_TYPES } from 'src/types/enum';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface RowActionProps extends CommandComponentProps {
  setClonedRowId: React.Dispatch<React.SetStateAction<number>>;
  setSendRowId: React.Dispatch<React.SetStateAction<number>>;
}

const RowAction = ({
  id,
  onExecute,
  rowData,
  setClonedRowId,
  setSendRowId
}: RowActionProps) => {
  const { t } = useTranslation();

  const handleClone = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setClonedRowId(rowData.id);
  }, []);

  const handleDelete = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    onExecute();
  }, []);

  const handleShare = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setSendRowId(rowData.id);
  }, []);

  if (id === ACTION_TYPES.EDIT) {
    return (
      <>
        {setSendRowId && (
          <IconButton onClick={handleShare} title={t(T.send)}>
            <ShareIcon width={20} />
          </IconButton>
        )}
        <IconButton onClick={handleClone} title={t(T.clone)}>
          <CloneIcon />
        </IconButton>
        <IconButton title={t(T.edit)}>
          <EditIcon />
        </IconButton>
      </>
    );
  }

  return (
    <IconButton onClick={handleDelete} title={t(T.delete)}>
      <DeleteIcon />
    </IconButton>
  );
};

export default RowAction;
