import { Delete } from '@mui/icons-material';
import { IconButton, ListItemIcon } from '@mui/material';
import { useCallback, useMemo } from 'react';
import Label from 'src/components/Label';
import SharedWithGeneric from 'src/components/ShareModal/SharedWith';
import { useSelector } from 'src/redux/store';
import { ACCOUNT_TYPE, CONTENT_SHARED_WITH_TYPE } from 'src/types/enum';
import { ORGANIZATION_ADMINISTRATOR } from '../utils/utils';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';

interface SharedWithProps {
  isLoading: boolean;
  handleSearch: any;
  removedAccessRights: any[];
  setRemovedAccessRight: React.Dispatch<React.SetStateAction<any[]>>;
  contentAccessRights: any;
  isExternalLinkedResource: any;
}

const SharedWith = ({
  isLoading,
  handleSearch,
  removedAccessRights,
  setRemovedAccessRight,
  contentAccessRights,
  isExternalLinkedResource
}: SharedWithProps) => {
  const handleRemoveAccessRight = useCallback((accessRight: any) => {
    setRemovedAccessRight((prev) => [...prev, accessRight.p_id]);
  }, []);

  const SharedWithListItemInfo = useCallback(
    ({ user }) => {
      const { user: loggedInUser } = useSelector((state) => state.auth);

      return (
        <>
          <Label showEllipses>{user.role}</Label>
          {loggedInUser?.accountType !== ACCOUNT_TYPE.REGULAR_USER &&
            !isExternalLinkedResource && (
              <ListItemIcon
                sx={{
                  minWidth: 'unset',
                  paddingLeft: '24px'
                }}
              >
                <IconButton
                  onClick={() => handleRemoveAccessRight(user)}
                  color="error"
                  size="small"
                  sx={{ minWidth: 'fit-content' }}
                  disabled={
                    user.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP &&
                    user.name === ORGANIZATION_ADMINISTRATOR
                  }
                >
                  <Delete />
                </IconButton>
              </ListItemIcon>
            )}
        </>
      );
    },
    [isExternalLinkedResource, handleRemoveAccessRight]
  );

  const accessRights = useMemo(() => {
    return (
      contentAccessRights?.filter(
        (user) => !removedAccessRights.includes(user.p_id)
      ) || []
    );
  }, [contentAccessRights, removedAccessRights]);

  const getPrimaryText = useCallback((user) => user.name, []);
  const getSecondaryText = useCallback(
    (user) =>
      user.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP
        ? `Members: ${user.members}`
        : user.email,
    []
  );

  return (
    <SharedWithGeneric
      handleSearch={handleSearch}
      isLoading={isLoading}
      noResultsMessage={T.noUserOrUserGroupHasAccessToResource}
      noSearchResultsMessage={T.noMatchingUsersOrUserGroupsFound}
      results={accessRights}
      getPrimaryText={getPrimaryText}
      getSecondaryText={getSecondaryText}
      ListItemInfo={SharedWithListItemInfo}
      keyField="p_id"
      disclaimer={T.authorizedToRemoveLowTierAccountTypesOnly}
    />
  );
};

export default SharedWith;
