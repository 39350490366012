import { TabContext } from '@mui/lab';
import {
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Tab
} from '@mui/material';
import { get } from 'lodash';
import { ReactElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import Loader from '../Loader';
import { StyledTabPanel, StyledTabs } from './styles';

export enum SHARE_MODAL_TABS {
  SEND = 'send',
  SENT_TO = 'sentTo'
}

interface ShareDialogProps {
  isOpen: boolean;
  title: string;
  isActionButtonVisible: boolean;
  isActionButtonDisabled: boolean;
  actionButtonText: string;
  onClose: () => void;
  handleActionButton: () => void;
  sendTabLabel: string;
  sentToTabLabel: string;
  children: [ReactElement, ReactElement];
  isActionInProgress: boolean;
  isDataLoading: boolean;
  selectedTab?: SHARE_MODAL_TABS;
  setSelectedTab?: React.Dispatch<React.SetStateAction<SHARE_MODAL_TABS>>;
  secondaryButtonText?: string;
  handleSecondaryButton?: () => void;
}

const ShareDialog = ({
  title,
  isOpen,
  onClose,
  children,
  isActionButtonDisabled = false,
  isActionButtonVisible = false,
  actionButtonText,
  handleActionButton,
  sendTabLabel,
  sentToTabLabel,
  isActionInProgress,
  isDataLoading,
  selectedTab: initialSelectedTab,
  setSelectedTab: setInitialSelectedTab,
  secondaryButtonText = T.cancel,
  handleSecondaryButton
}: ShareDialogProps) => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(initialSelectedTab || SHARE_MODAL_TABS.SEND);

  const handleTabChange = useCallback((_, newTab) => {
    setSelectedTab(newTab);
    if (setInitialSelectedTab) setInitialSelectedTab(newTab);
  }, []);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={onClose}>
      <DialogTitle>
        <Typography
          variant="h3"
          title={title}
          sx={{
            fontWeight: 'normal',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent sx={{ overflow: 'hidden' }}>
        <TabContext value={selectedTab}>
          <StyledTabs value={selectedTab} onChange={handleTabChange}>
            <Tab
              value={SHARE_MODAL_TABS.SEND}
              label={<Typography>{t(sendTabLabel)}</Typography>}
            />
            <Tab
              value={SHARE_MODAL_TABS.SENT_TO}
              label={<Typography>{t(sentToTabLabel)}</Typography>}
            />
          </StyledTabs>

          {isActionInProgress && <Loader />}
          {isDataLoading ? (
            <Loader />
          ) : (
            <>
              <StyledTabPanel value={SHARE_MODAL_TABS.SEND}>
                {children[0]}
              </StyledTabPanel>

              <StyledTabPanel value={SHARE_MODAL_TABS.SENT_TO}>
                {children[1]}
              </StyledTabPanel>
            </>
          )}
        </TabContext>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleSecondaryButton || onClose} variant="outlined" size="small">
          {t(secondaryButtonText)}
        </Button>

        {isActionButtonVisible && (
          <Button
            onClick={handleActionButton}
            variant="contained"
            type="submit"
            disabled={isActionButtonDisabled}
            size="small"
            color="secondary"
          >
            {t(actionButtonText)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ShareDialog;
