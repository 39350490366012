import { Box, Link, TextField, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddIcon } from 'src/assets/add.svg';
import Loader from 'src/components/Loader';
import DialogLayout from 'src/components/MiniExperts/CommonDialogLayout';
import useLazyQuery from 'src/hooks/useLazyQuery';
import useMutation from 'src/hooks/useMutation';
import {
  useCreateQuestionnaireMutation,
  useEditQuestionnaireMutation,
  useLazyGetQuestionnaireQuery
} from 'src/services/api';
import { ErrorContext } from 'src/utils/errorMappings';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import { v4 as uuidV4 } from 'uuid';
import { StyledLinkButton } from '../../styles';
import QuestionRow from './QuestionRow';
import { useFieldArray, useForm } from 'react-hook-form';
import { QUESTION_CHARACTER_LIMIT, trimValue } from '../../utils/utils';
import { get } from 'lodash';

interface CreateQuestionnaireModalProps {
  isOpen: boolean;
  onClose: () => void;
  cloneID?: number;
  questionnaireToEdit?: { id: number; name: string };
}

export const CreateQuestionnaireModal: FC<CreateQuestionnaireModalProps> = ({
  isOpen,
  onClose,
  questionnaireToEdit,
  cloneID
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const questionsContainerRef = useRef<HTMLDivElement>(null);
  const [isQuestionnaireAssociated, setIsQuestionnaireAssociated] =
    useState(false);

  const [deletedQuestionIds, setDeletedQuestionIds] = useState<
    (number | string)[]
  >([]);

  const formRef = useRef<HTMLFormElement>(null);
  const {
    getValues,
    handleSubmit,
    setValue,
    register,
    watch,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: questionnaireToEdit?.name || '',
      questions: [{ id: uuidV4(), question: '', isNewQuestion: true }]
    }
  });

  const {
    fields: questionsFields,
    append,
    remove,
    move
  } = useFieldArray({
    name: 'questions',
    control,
    keyName: 'tempId'
  });

  const [createQuestionnaire] = useMutation({
    api: useCreateQuestionnaireMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [fetchQuestionnaire, { isLoading }] = useLazyQuery({
    api: useLazyGetQuestionnaireQuery,
    errorContext: ErrorContext.DOC_ANALYST
  });

  const [editQuestionnaire] = useMutation({
    api: useEditQuestionnaireMutation,
    errorContext: ErrorContext.DOC_ANALYST
  });

  useEffect(() => {
    if (cloneID || questionnaireToEdit?.id) {
      fetchQuestionnaire({
        params: {
          params: {
            id: cloneID || questionnaireToEdit?.id
          }
        },
        successCallback: (data) => {
          setIsQuestionnaireAssociated(
            cloneID ? false : data.is_questionnaire_associated
          );
          const questions = data.data.map(({ id, question }) => ({
            id: !!questionnaireToEdit ? id : uuidV4(),
            question
          }));
          setValue('questions', questions);
        }
      });
    }
  }, [cloneID, questionnaireToEdit]);

  const questions = watch('questions');
  
  const hasEmptyQuestion = questions.some((q) => q.question.trim() === '');

  const findQuestion = useCallback((questions: any[], id: number | string) => {
    const index = questions.findIndex((q) => q.id === id);
    return {
      question: questions[index],
      index
    };
  }, []);

  const moveQuestion = useCallback((id: number | string, atIndex: number) => {
    const questions = getValues('questions');
    const { index } = findQuestion(questions, id);
    move(index, atIndex);
  }, []);

  const moveQuestionToId = useCallback(
    (id: number | string, moveToId: number | string) => {
      const questions = getValues('questions');
      const { index: atIndex } = findQuestion(questions, moveToId);
      const { index } = findQuestion(questions, id);
      move(index, atIndex);
    },
    []
  );

  const addNewQuestion = useCallback(() => {
    append({ id: uuidV4(), question: '', isNewQuestion: true });
  }, []);

  const removeQuestion = useCallback(
    (index: number, id: number | string, isNewQuestion: boolean) => {
      remove(index);

      if (questionnaireToEdit && !isNewQuestion) {
        setDeletedQuestionIds((prev) => [...prev, id]);
      }
    },
    []
  );

  const handleUpdateQuestionnaire = useCallback(
    ({ title, questions }) => {
      const newQuestions = [],
        editedQuestions = [];
      questions.forEach(({ id, question, isNewQuestion }) => {
        if (isNewQuestion) {
          if (question !== '') {
            newQuestions.push(question);
          }
        } else {
          editedQuestions.push({ id, question });
        }
      });

      const optionalParams = {};
      if (newQuestions.length) {
        optionalParams['add_list'] = JSON.stringify(newQuestions);
      }
      if (deletedQuestionIds.length) {
        optionalParams['delete_list'] = deletedQuestionIds;
      }

      editQuestionnaire({
        params: {
          params: {
            questionnaire_id: questionnaireToEdit?.id,
            edit_list: JSON.stringify(editedQuestions),
            ...optionalParams
          }
        },
        successMsg: t(T.questionnaireUpdatedSuccess),
        fallbackMsg: t(T.questionnaireUpdateFailure),
        successCallback: onClose
      });
    },
    [deletedQuestionIds]
  );

  const handleAddQuestionnaire = useCallback(({ title, questions }) => {
    const question_list = questions.map((q) => q.question).filter((q) => q);
    createQuestionnaire({
      params: {
        params: {
          title,
          question_list: JSON.stringify(question_list)
        }
      },
      successMsg: T.questionnaireCreationSuccess,
      successCallback: onClose
    });
  }, []);

  const handleConfirmation = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  const onSubmit = useCallback(
    (data) => {
      if (questionnaireToEdit) {
        handleUpdateQuestionnaire(data);
      } else {
        handleAddQuestionnaire(data);
      }
    },
    [questionnaireToEdit, handleAddQuestionnaire, handleUpdateQuestionnaire]
  );

  const focusNode = useCallback((node) => {
    node?.focus();
  }, []);

  return (
    <DialogLayout
      isOpen={isOpen}
      onClose={onClose}
      dialogTitle={questionnaireToEdit ? T.questionnaire : T.addQuestionnaire}
      confirmationButtonText={questionnaireToEdit ? T.update : T.save}
      disableButton={isQuestionnaireAssociated}
      handleConfirmation={handleConfirmation}
      sxDialog={{
        '& .MuiDialog-paper.MuiPaper-root': { height: '75%', maxWidth: '720px' }
      }}
      maxWidth={false}
    >
      <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
        {isLoading ? (
          <Loader />
        ) : (
          <Box display="flex" flexDirection="column" maxHeight="100%">
            {isQuestionnaireAssociated && (
              <Typography color={'error'} marginBottom={2}>
                {t(T.questionnaireAssociationMsg)}
              </Typography>
            )}
            <TextField
              {...register('title', { required: true, setValueAs: trimValue })}
              placeholder={t(T.questionnaireTitlePlaceholder)}
              disabled={!!questionnaireToEdit}
              fullWidth
              sx={{
                '& .MuiInputBase-input': {
                  padding: 1
                },
                '& .Mui-error .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.colors.error.main} !important`
                },
                '& .Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
                  borderWidth: '1.5px'
                }
              }}
              inputRef={focusNode}
              error={!!errors?.title}
            />

            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              paddingBottom="10px"
              marginTop="24px"
              overflow="auto"
              maxHeight="fit-content"
              ref={questionsContainerRef}
            >
              <DndProvider backend={HTML5Backend}>
                {questionsFields.map(({ id, isNewQuestion }, index) => (
                  <QuestionRow
                    id={id}
                    key={id}
                    index={index}
                    {...register(`questions.${index}.question`, {
                      required: true,
                      maxLength: QUESTION_CHARACTER_LIMIT,
                      setValueAs: trimValue
                    })}
                    moveQuestion={moveQuestion}
                    removeQuestion={removeQuestion}
                    isNewQuestion={isNewQuestion}
                    disableActionButtons={
                      isQuestionnaireAssociated || questions.length === 1
                    }
                    isReorderingVisible={!questionnaireToEdit}
                    moveQuestionToId={moveQuestionToId}
                    error={get(errors, `questions.${index}.question`)}
                    watch={watch}
                  />
                ))}
              </DndProvider>
            </Box>
            <StyledLinkButton
              onClick={addNewQuestion}
              disabled={
                isQuestionnaireAssociated ||
                hasEmptyQuestion ||
                questions.length >= 300
              }
            >
              <AddIcon width="16px" />
              <Link fontSize="16px" fontWeight={500} underline="none">
                {t(T.addQuestion)}
              </Link>
            </StyledLinkButton>
          </Box>
        )}
      </form>
    </DialogLayout>
  );
};

export default CreateQuestionnaireModal;
