import { TabPanel } from '@mui/lab';
import { styled, Tabs } from '@mui/material';

export const StyledTabPanel = styled(TabPanel)(`
  padding: 0 !important;
  minHeight: 241px;
  height: 241px;
  maxHeight: 241px;
`);

export const StyledTabs = styled(Tabs)(({ theme }) => {
  return {
    marginTop: '6px',
    marginBottom: '12px',
    '& .MuiButtonBase-root.MuiTab-root.Mui-selected': {
      backgroundColor: `${theme.colors.secondary.main} !important`
    }
  };
});
