import { ASSISTANT_TABS, SORT_TYPES } from 'src/types/enum';
import { IAssistantProps, IAssistantTabProps } from './types';
import { StringKeys } from 'src/types/base';

export const BASE_64_PREFIX = 'data:image/svg+xml;base64,';
export const MAX_NAME_LENGTH = 50;
export const MAX_DESC_LENGTH = 300;
export const MAX_INSTRUCTIONS_LENGTH = 4000;
export const MAX_CONVERSATION_STARTER_LENGTH = 300;
export const MAX_CONVERSATION_STARTERS = 4;
export const MAX_ICON_FILE_SIZE = 500; // KB size
export const MAX_FILES_ATTACHED = 50;
export const DEFAULT_ASSISTANT_FILTER = 'created_on desc';

export const defaultAssistantProps: IAssistantProps = {
  assistantIcon: '',
  aiModel: null,
  isWebSearch: false,
  worldKnowledge: true,
  instructions: ''
};
export const sortList = [
  // {
  //   id: 1,
  //   name: 'Recently Used',
  //   code: 'recently-used'
  // },
  {
    id: 2,
    name: 'Newest to Oldest',
    code: 'created_on desc'
  },
  {
    id: 3,
    name: 'Oldest to Newest',
    code: 'created_on asc'
  },
  {
    id: 4,
    name: 'A-Z',
    code: 'name asc'
  },
  {
    id: 5,
    name: 'Z-A',
    code: 'name desc'
  }
];

export const initialAssistantTabs: IAssistantTabProps[] = [
  {
    label: '',
    value: ASSISTANT_TABS.ALL
  },
  {
    label: '',
    value: ASSISTANT_TABS.CREATED_BY_ME
  }
];

export const sortArrayByKeyAndType = (
  array: any,
  key: string,
  sortType: string
) => {
  let sortedArray: any = [...array];
  if (sortType === SORT_TYPES.ASCENDING) {
    return sortedArray.sort((a: any, b: any) =>
      a[key].toLowerCase().localeCompare(b[key].toLowerCase())
    );
  } else {
    return sortedArray.sort((a: any, b: any) =>
      b[key].toLowerCase().localeCompare(a[key].toLowerCase())
    );
  }
};

const getBase64StringFromDataURL = (dataURL) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');

export const convertImageToBase64 = (file, setIcons, icons, icon) => {
  let reader = new FileReader();
  reader.onloadend = function () {
    console.log('RESULT', reader.result);
  };
  reader.readAsDataURL(file);
};

export const compareAssistantObject = (obj1: StringKeys, obj2: StringKeys) => {
  if (obj1 === obj2) {
    return true;
  }
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !compareAssistantObject(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
