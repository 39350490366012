import React, { useEffect, useMemo, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { TRANSLATION_CONSTANTS as T } from 'src/utils/translations';
import {
  Box,
  InputLabel,
  TextField,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { colorList } from '../../utils/utils';
import { getInitials } from 'src/utils/utils';
import Select from 'src/components/Select';
import { Controller, useForm } from 'react-hook-form';
import { FormValues } from './type';
import { renderColor } from './styles';
import SearchableAutoComplete from 'src/components/SearchableAutoComplete';
import { GroupsOutlined, PersonOutlineOutlined } from '@mui/icons-material';
import {
  useLazyGetUserOrUserGroupsQuery,
  useLazyGetContentAccessRightsQuery
} from 'src/services/api';
import {
  CONTENT_SHARED_WITH_TYPE,
  ACCOUNT_TYPE,
  DEFAULT_CONTENT_ROLES
} from 'src/types/enum';
import useLazyQuery from 'src/hooks/useLazyQuery';
import { DataApiInputParams } from 'src/types/api';
import Loader from 'src/components/Loader';
import { StringKeys } from 'src/types/base';
import { isEmailValid } from 'src/utils/utils';
import { IS_NON_AD_ENABLED } from 'src/config';
import { useSelector } from 'src/redux/store';
import shuffle from 'lodash/shuffle';

type CreateUpdateDialogProps = {
  isOpen: boolean;
  message: string;
  cardDetails?: any;
  onConfirm: (
    data: any,
    addedUsers?: StringKeys[],
    removedUsers?: StringKeys[]
  ) => void;
  onClose: () => void;
};

const CreateUpdateDialog = ({
  cardDetails,
  isOpen,
  onConfirm,
  onClose
}: CreateUpdateDialogProps) => {
  const [
    fetchContentAccessDetail,
    { data: contentAccessRights, isLoading: isLoadingPermissions }
  ] = useLazyQuery<DataApiInputParams, any[]>({
    api: useLazyGetContentAccessRightsQuery
  });

  const { t } = useTranslation();

  const { user: loggedInUser } = useSelector((state) => state.auth);

  const form = useForm<FormValues>({
    defaultValues: {
      name: '',
      color: shuffle(colorList)?.[0],
      users: []
    }
  });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = form;

  const watchUsers = watch('users', []);

  useEffect(() => {
    if (cardDetails) {
      setValue('name', cardDetails?.name);
      setValue('color', cardDetails?.initials_color);
      fetchContentAccessDetail({
        params: {
          params: {
            id: cardDetails.id,
            roles: `${DEFAULT_CONTENT_ROLES.CONTROLLER}`
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (contentAccessRights?.length) {
      setValue('users', contentAccessRights);
    }
  }, [contentAccessRights]);

  const showError = useMemo(() => {
    let containsRegularUsers = false;
    let controllerAssignmentError = false;
    if (watchUsers?.length) {
      containsRegularUsers = watchUsers.some(
        (user) => user?.account_type?.code === ACCOUNT_TYPE.REGULAR_USER
      );
      controllerAssignmentError =
        loggedInUser.accountType !== ACCOUNT_TYPE.ORGANIZATION_ADMINISTRATOR &&
        watchUsers.some((user) => !user?.type_id);
    }
    return { containsRegularUsers, controllerAssignmentError };
  }, [watchUsers]);

  const handleColorChange = (val: any) => {
    setValue('color', val);
  };

  const validateEmail = useCallback(
    (value) => {
      if (typeof value === 'string') {
        const isValid = isEmailValid(value);
        if (isValid) {
          clearErrors('users');
          return true;
        } else {
          setError('users', {
            type: 'manual',
            message: t(T.invalidEmail)
          });
          return false;
        }
      } else if (errors.users) {
        clearErrors('users');
      }
      return true;
    },
    [errors]
  );

  const handleConfirm = handleSubmit((data: any) => {
    const { users, ...rest } = data;
    if (showError.containsRegularUsers || showError.controllerAssignmentError) {
      return;
    }
    let removedUsers = [];
    let addedUsers = [...users];
    if (contentAccessRights?.length) {
      removedUsers = contentAccessRights?.filter(
        (user) => !users?.some((newUser) => newUser.id === user.id)
      );
      addedUsers = users?.filter(
        (newUser) =>
          !contentAccessRights?.some((user) => user.id === newUser.id)
      );
    }

    const updatedDoc = {
      ...cardDetails,
      name: rest.name,
      initials: getInitials(rest.name),
      initials_color: rest.color?.name || rest.color
    };

    if (cardDetails) {
      updatedDoc[`${cardDetails.id}`] = { ...updatedDoc };
    }
    onConfirm(updatedDoc, addedUsers, removedUsers);
    onClose();
  });

  const onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleConfirm();
    }
  };

  return (
    <Dialog
      open={isOpen}
      keepMounted
      onClose={onClose}
      fullWidth
      aria-describedby="confirmation dialog"
    >
      <DialogTitle>
        {t(cardDetails ? T.updateWorkspace : T.createWorkspace)}
      </DialogTitle>
      {isLoadingPermissions && <Loader />}
      <DialogContent>
        <Box>
          <InputLabel>{t(T.workspaceName)}</InputLabel>
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t(T.required, { name: t(T.workspaceName) })
              },
              maxLength: {
                value: 30,
                message: t(T.maxLength, {
                  name: t(T.workspaceName),
                  length: 30
                })
              }
            }}
            render={({ field, formState: { errors } }) => (
              <TextField
                autoFocus
                onKeyPress={onPressEnter}
                fullWidth
                sx={(theme) => ({
                  mb: theme.spacing(2),
                  '& .MuiOutlinedInput-root': {
                    borderRadius: theme.spacing(1)
                  },
                  '& .MuiOutlinedInput-input': {
                    padding: '8px 14px'
                  }
                })}
                value={field.value}
                required
                error={!!errors.name}
                helperText={errors.name?.message || ''}
                {...field}
              />
            )}
          />
        </Box>
        <Box>
          <InputLabel>{t(T.workspaceColor)}</InputLabel>
          <Controller
            name="color"
            control={control}
            rules={{
              required: {
                value: true,
                message: t(T.required, { name: t(T.workspaceColor) })
              }
            }}
            render={({ field, formState: { errors } }) => (
              <Select
                {...field}
                id="color"
                valueKey={'name'}
                width={'100%'}
                error={!!errors.color}
                required
                dataSource={[...colorList]}
                sx={{
                  '& .MuiInputBase-input': {
                    height: '2px !important'
                  }
                }}
                renderValue={renderColor}
                renderOptions={renderColor}
                onChange={(e) => handleColorChange(e.target.value)}
              />
            )}
          />
        </Box>
        <Box mt={2}>
          <InputLabel>{t(T.workspaceController)}</InputLabel>
          <Controller
            name="users"
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, ...rest } }) => (
              <SearchableAutoComplete
                {...rest}
                searchApi={useLazyGetUserOrUserGroupsQuery}
                optionLabel={'name'}
                freeSolo={IS_NON_AD_ENABLED && loggedInUser?.isNonTrialUser}
                customValidation={validateEmail}
                onChange={(_, value) => {
                  onChange(value);
                }}
                searchApiParams={{
                  search_group: true
                }}
                autoSelect
                placeholder={t(T.searchForUserOrUserGroup)}
                multiple={true}
                required={true}
                customLabel={true}
                hideEndAdornment={true}
                renderOption={(props, option) => (
                  <ListItem
                    key={`${option.code}-${option.value}`}
                    sx={(theme) => ({
                      padding: `${theme.spacing(1)} ${theme.spacing(2)}`
                    })}
                    {...props}
                  >
                    <ListItemIcon
                      sx={{ minWidth: 'unset', paddingRight: '16px' }}
                    >
                      {option.code === CONTENT_SHARED_WITH_TYPE.USER_GROUP ? (
                        <GroupsOutlined />
                      ) : (
                        <PersonOutlineOutlined />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={option.name}
                      secondary={option.email}
                    />
                  </ListItem>
                )}
              />
            )}
          />
          {!!errors.users && (
            <Typography color="error">{errors.users?.message}</Typography>
          )}

          {showError.containsRegularUsers && (
            <Typography
              color={'error'}
              fontWeight="bold"
              sx={{ fontSize: '13px' }}
            >
              {t(T.regularUserAssignmentToWorkspace)}
            </Typography>
          )}
          {showError.controllerAssignmentError && (
            <Typography
              color={'error'}
              fontWeight="bold"
              sx={{ fontSize: '13px' }}
            >
              {t(T.contollerAssignmentError)}
            </Typography>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" size="small">
          {t(T.cancel)}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          size="small"
          color="secondary"
        >
          {t(cardDetails ? T.update : T.create)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUpdateDialog;
